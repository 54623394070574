var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "keys-match" }, [
      _vm._m(1),
      _c(
        "div",
        { staticClass: "match-content" },
        _vm._l(_vm.jobItems, function (item, index) {
          return _c("div", { key: index, staticClass: "match-content-item" }, [
            _c(
              "div",
              {
                staticClass: "item-common item-excel",
                class: _vm.mapClass(index),
              },
              [_vm._v(_vm._s(item.importItemName))]
            ),
            _c("div", { staticClass: "item-common item-space" }),
            _c(
              "div",
              { staticClass: "item-common item-system" },
              [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "right-start",
                      width: "200",
                      "popper-class": "match-popover",
                      trigger: "click",
                    },
                    model: {
                      value: item.modelShow,
                      callback: function ($$v) {
                        _vm.$set(item, "modelShow", $$v)
                      },
                      expression: "item.modelShow",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "item-system-key",
                        attrs: { slot: "reference" },
                        on: {
                          click: function ($event) {
                            return _vm.handleShowModal($event, index)
                          },
                        },
                        slot: "reference",
                      },
                      [
                        _c("span", { staticClass: "matching-text" }, [
                          _vm._v(_vm._s(_vm._f("mapText")(item.itemName))),
                        ]),
                        _c("span", { staticClass: "matching-error" }, [
                          _vm._v(_vm._s(item.errMessage)),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "item-system-select" },
                      _vm._l(_vm.systemItems, function (item, subIndex) {
                        return _c(
                          "div",
                          {
                            key: subIndex,
                            staticClass: "select-item",
                            class: { "seletct-item-requiry": item.required },
                            on: {
                              click: function ($event) {
                                return _vm.handleMatch(item, index)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "match-footer" },
        [
          _vm.enableNext
            ? _c(
                "el-button",
                {
                  staticClass: "next-btn enable-next",
                  attrs: { type: "success" },
                  on: { click: _vm.handleSetMatch },
                },
                [_vm._v("下一步 >")]
              )
            : _vm._e(),
          !_vm.enableNext
            ? _c(
                "el-button",
                {
                  staticClass: "next-btn disable-next",
                  attrs: { type: "info" },
                },
                [_vm._v("下一步 >")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "match-tips" }, [
      _c("span", [_vm._v("Tips: ")]),
      _vm._v("“职位描述”和“职位分析”这两个字段可重复选择。"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "match-header" }, [
      _c("div", { staticClass: "match-header-item header-text" }, [
        _vm._v("请检测是否匹配正确："),
      ]),
      _c("div", { staticClass: "match-header-item header-excel" }, [
        _vm._v("Excel字段"),
      ]),
      _c("div", { staticClass: "match-header-item header-space" }),
      _c("div", { staticClass: "match-header-item header-system" }, [
        _vm._v("系统字段"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }