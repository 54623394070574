<template>
	<div>
        <div class="item-common item-name">
            <div :class="{'update-name-len': !job.name || job.name.length < 9}" class="item-name-text">
                <el-checkbox
					v-model="job.checked"
					@change="handleJobCheck"
					class="item-checked">
				</el-checkbox>
                <div
					:class="{'null-class': !job.name}"
					class="item-name-result text-ellipsis"
					v-if="!job.nameEdit"
					@click="handleEdit('name')">
                    {{job.name | formatNull}}
                </div>
            </div>
            <div class="item-input-editing" v-if="job.nameEdit">
                <el-input
					v-model="nameInput"
					@keyup.enter.native="handleEnter('name', index, $event)"
					@blur="(ev) => handleBlur(ev, 'name')"
					v-focus>
				</el-input>
            </div>
        </div>
        <el-popover
            placement="bottom"
            width="548"
            @hide="handlePopHide('description')"
            v-model="job.descriptionVisible"
            trigger="click">
            <div class="item-des-wrap">
                <div class="item-header-common item-des-header">
                    <span>职位描述</span>
                    <span v-if="!job.descriptionEdit" @click="handleEdit('description')">编辑</span>
                    <span v-if="job.descriptionEdit" @click="handleSave('description')">保存</span>
                </div>
                <div class="item-des-edit">
                    <div class="des-edit-result" v-if="!job.descriptionEdit">
                        <pre class="pre-text" v-html="job.description"></pre>
                    </div>
                    <div class="des-edit-box" v-if="job.descriptionEdit">
                        <el-input
                            :rows="11"
                            type="textarea"
                            resize="none"
                            :maxlength="8000"
                            placeholder="请输入内容"
							v-model="descriptionInput"
							@input="(val) => handleTransBr(val, 'description')">
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="item-common item-des" slot="reference">
                <div
					:class="{'null-class': !job.description, 'update-des-len': !job.description || job.description.length < 13}"
					class="des-text position-absolut text-ellipsis">
                    {{job.description | formatNull}}
                </div>
            </div>
        </el-popover>
        <div class="item-common item-grade">
            <div
				@click="handleEdit('expectedRank')"
				class="item-grade-text"
				v-if="!job.expectedRankEdit">
                <div class="grade-result">
                    {{job.expectedRank | formatRank}}
                </div>
            </div>
            <div class="item-input-editing" v-if="job.expectedRankEdit">
                <el-input
					v-model="expectedRankInput"
					@keyup.enter.native="handleEnter('expectedRank', index, $event)"
					@blur="(ev) => handleBlur(ev, 'expectedRank')"
					@input="(val) => handleRecruitInput(val, 'expectedRank')"
					v-focus>
				</el-input>
            </div>
        </div>
        <el-popover
            placement="bottom"
            width="308"
            popper-class="salary-popover"
            v-model="job.maxSalaryVisible"
            @show="handleEdit('salary')"
            @hide="handlePopHide('salary')"
            trigger="click">
            <div class="item-salary-edit">
                <div class="salary-edit-input" v-if="job.salaryEdit">
                    <el-input
						:class="{'valid-salary-err': job.minSalaryErr}"
						name="minSalary"
						v-model="minSalaryInput"
						type="number"
						@keyup.enter.native="handleSalary('minSalary', index, $event)"
						@blur="(ev) => handleBlur(ev, 'minSalary')"
						@input="(val) => handleSalaryInput(val, 'minSalary')"
						v-focus>
						<template slot="suffix">K&nbsp;&nbsp;</template>
					</el-input>
                    <span class="salary-space">——</span>
                    <el-input
						:class="{'valid-salary-err': job.maxSalaryErr}"
						name="maxSalary"
						v-model="maxSalaryInput"
						type="number"
						@keyup.enter.native="handleSalary('maxSalary', index, $event)"
						@blur="(ev) => handleBlur(ev, 'maxSalary')"
						@input="(val) => handleSalaryInput(val, 'maxSalary')">
						<template slot="suffix">K&nbsp;&nbsp;</template>
					</el-input>
                </div>
                <div class="error-tip" v-if="job.minSalaryErr || job.maxSalaryErr">
                    <div class="error-tip-item">{{job.minSalaryErr}}</div>
                    <div class="error-tip-item">{{job.maxSalaryErr}}</div>
                </div>
            </div>
            <div class="item-common item-salary" slot="reference">
                <div v-bind:class="{'null-class': !job.maxSalary && !job.minSalary}" class="item-salary-text position-absolut text-ellipsis">
                    {{handleFilterSalary(index)}}
                </div>
            </div>
        </el-popover>
        <el-popover
            placement="bottom"
            width="548"
            v-model="job.analysisVisible"
            @hide="handlePopHide('analysis')"
            trigger="click">
            <div class="item-analyse-wrap">
                <div class="item-header-common item-analyse-header">
                    <span>职位分析</span>（请至少选择一项填写）
                    <span v-if="!job.analysisEdit" @click="handleEdit('analysis')">编辑</span>
                    <span v-if="job.analysisEdit" @click="handleSave('analysis')">保存</span>
                </div>
                <div class="item-analysis-edit">
                    <div class="item-analyse-result" v-if="!job.analysisEdit">
                        <div class="analysis-item analysis-intension" v-if="job.expectedRank">
                            <span>职级：</span>
                            <pre class="pre-text" v-html="job.expectedRank"></pre>
                        </div>
                        <div class="analysis-item analysis-company" v-if="job.targetCompany">
                            <span>目标公司：</span>
                            <pre class="pre-text" v-html="job.targetCompany"></pre>
                        </div>
                        <div class="analysis-item analysis-more">
                            <span>其他：</span>
                            <pre class="pre-text" v-html="job.analysis"></pre>
                        </div>
                    </div>
                    <div class="analyse-edit-box" v-if="job.analysisEdit">
                        <div class="edit-box-item edit-expect-rank">
                            <span class="sub-title">职级：</span>
                            <el-input
                                type="textarea"
                                :maxlength="200"
                                resize="none"
                                placeholder="请输入内容"
								v-model="expectedRankInput"
								@input="(val) => handleTransBr(val, 'expectedRank')">
                            </el-input>
                        </div>
                        <div class="edit-box-item edit-expect-firm">
                            <span class="sub-title">目标公司：</span>
                            <el-input
                                type="textarea"
                                :rows="2"
                                resize="none"
                                :maxlength="200"
                                placeholder="请输入内容"
								v-model="targetCompanyInput"
								@input="(val) => handleTransBr(val, 'targetCompany')">
                            </el-input>
                        </div>
                        <div class="edit-box-item edit-expect-more">
                            <span class="sub-title">其他：</span>
                            <div class="add-info-text">
                                <div class="info-content">如新增替补原因、汇报对象、团队情况、面试流程、薪资福利</div>
                                <el-input
                                    type="textarea"
                                    :maxlength="1600"
                                    placeholder="请输入内容"
                                    :rows="6"
                                    resize="none"
									v-model="analysisInput"
									@input="(val) => handleTransBr(val, 'analysis')">
                                </el-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-common item-analysis" slot="reference">
                <div v-bind:class="{'null-class': handleAnalyseClass(job), 'update-analysis-len': (job.expectedRank + job.targetCompany + job.analysis).length < 14}" class="analysis-text position-absolut text-ellipsis">
                    {{job | formatNullAnalyse}}
                </div>
            </div>
        </el-popover>
        <el-popover
            placement="bottom"
            width="548"
            v-model="job.locationsVisible"
            trigger="click">
            <div class="item-location-edit">
                <LocationMultiple v-if="job.locationsVisible" :citys="job.locations" :index="index" v-on:location-save="handleLocationSave" />
            </div>
            <div class="item-common item-location" slot="reference">
                <div v-bind:class="{'null-class': !job.locations || job.locations.length == 0, 'update-location-len': getCityInfo(job.locations).length < 4}" class="location-text position-absolut text-ellipsis">
                    {{getCityInfo(job.locations) | formatNull}}
                </div>
            </div>
        </el-popover>
        <div class="item-common item-recruiting">
            <div
				@click="handleEdit('recruitingCount')"
				:class="{'null-class': !job.recruitingCount}"
				class="item-recruiting-text"
				v-if="!job.recruitingCountEdit">
                <div class="recruiting-result">
                    {{job.recruitingCount | formatCount}}
                </div>
            </div>
            <div class="item-input-editing" v-if="job.recruitingCountEdit">
                <el-input
					v-model="recruitingCountInput"
					@keyup.enter.native="handleEnter('recruitingCount', index, $event)"
					@blur="(ev) => handleBlur(ev, 'recruitingCount')"
					@input="(val) => handleRecruitInput(val, 'recruitingCount')"
					v-focus>
				</el-input>
                <span class="count-tip">人</span>
            </div>
        </div>
        <div class="job-drop-item drop-item-year">
            <div class="drop-item-select">
                <el-dropdown trigger="click" placement="bottom">
                    <div class="select-result">{{job.minYearOfExperience | formatCommon('year')}}</div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="item in experienceData" :key="item.value" @click.native="handleSelect('minYearOfExperience', item.value, index)">{{item.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="job-drop-item drop-item-degree">
            <div class="drop-item-select">
                <el-dropdown trigger="click" placement="bottom">
                    <div class="select-result">{{job.minDegree | formatCommon('degree')}}</div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="item in degreeData" :key="item.value" @click.native="handleSelect('minDegree', item.value, index)">{{item.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="job-drop-item drop-item-emergency">
            <div class="drop-item-select">
                <el-dropdown trigger="click" placement="bottom">
                    <div class="select-result">{{job.emergencyDegree | formatCommon('emergency')}}</div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="item in emergencyData" :key="item.value" @click.native="handleSelect('emergencyDegree', item.value, index)">{{item.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="job-drop-item drop-item-difficult">
            <div class="drop-item-select">
                <el-dropdown trigger="click" placement="bottom">
                    <div class="select-result">{{job.difficultDegree | formatCommon('difficult')}}</div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="item in difficultData" :key="item.value" @click.native="handleSelect('difficultDegree', item.value, index)">{{item.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <!--<el-popover-->
            <!--placement="bottom"-->
            <!--width="308"-->
            <!--popper-class="commission-popover"-->
            <!--v-model="job.commissionVisible"-->
            <!--trigger="click">-->
            <!--<div class="item-commission-edit">-->
                <!--<div class="item-header-common item-header-commission">-->
                    <!--<span>签约佣金</span>-->
                    <!--<span @click="handleCommissionSave(index, $event)">保存</span>-->
                <!--</div>-->
                <!--<div class="item-content-tab">-->
                    <!--<span v-bind:class="{'tab-active': job.commissionType === 0}" @click="handleToggleType('rate', index)">按比例</span> |-->
                    <!--<span v-bind:class="{'tab-active': job.commissionType === 1}" @click="handleToggleType('number', index)">固定佣金</span>-->
                <!--</div>-->
                <!--<div class="item-content-input">-->
                    <!--<el-input :value="job.commissionValue > 0 ? job.commissionValue : ''" type="number" @keyup.enter.native="handleCommissionEnter(index, $event)" @input.native="handleCommissionInput(index, $event)" v-focus></el-input>-->
                    <!--<span class="commission-type-text">{{job.commissionType == 0 ? '%' : 'K'}}</span>-->
                <!--</div>-->
            <!--</div>-->
            <!--<div class="item-common item-commission" slot="reference">-->
                <!--<div v-bind:class="{'null-class': !job.commissionValue || job.commissionValue == 0}" class="item-salary-text position-absolut text-ellipsis">-->
                    <!--{{job.commissionValue | formatCommission(index, job.commissionType) }}-->
                <!--</div>-->
            <!--</div>-->
        <!--</el-popover>-->
        <!--<div class="job-drop-item drop-item-guarantee">-->
            <!--<div class="drop-item-select">-->
                <!--<el-dropdown trigger="click" placement="bottom">-->
                    <!--<div class="select-result">{{job.guaranteePeriod | formatCommon('guarantee')}}</div>-->
                    <!--<el-dropdown-menu slot="dropdown">-->
                        <!--<el-dropdown-item v-for="item in guaranteeData" :key="item.value" @click.native="handleSelect('guaranteePeriod', item.value, index)">{{item.label}}</el-dropdown-item>-->
                    <!--</el-dropdown-menu>-->
                <!--</el-dropdown>-->
            <!--</div>-->
        <!--</div>-->
	</div>
</template>
<script>
import LocationMultiple from './LocationMultiple.vue';

import ProvinceJson from '#/js/config/provinceThreeData.json';
import DegreeJson from '../config/degreesArr.json';
import DifficultJson from '../config/difficultyArr.json';
import EmergencyJson from '../config/emergencyDegreeArr.json';
import ExpJson from '../config/experienceArr.json';
import GuaranteeJson from '../config/guaranteePeriodArr.json';

import jobCreateMultiService from '#/js/service/jobCreateMultiService.js';

export default {
	name: 'EditChild',
	props: {
		job: {
			type: Object,
			default: {}
		},
		index: {
			type: Number,
			default: null
		},
        batchNo: {
		    type: String,
            default: ''
        }
	},
	components: {
		LocationMultiple
	},
	data() {
		return {
			checkAll: true,
			nameInput: "",
			descriptionInput: "",
			minSalaryInput: "",
			maxSalaryInput: "",
			expectedRankInput: "",
			targetCompanyInput: "",
			analysisInput: "",
			recruitingCountInput: ""
		}
	},
	created() {
		// this.jobData.forEach((item, index) =>{
		// 	this.$set(this.job, 'checked', true);
		// });
	},
	filters: {
		formatCount(val) {
			if(!val) {
				return '请输入...';
			}
			return val + '人';
		},
		formatCommon(val, type) {
			let label = '请输入';
			let data = null;

			switch(type)
			{
				case 'year':
					data = ExpJson;
					break;
				case 'degree':
					data = DegreeJson;
					break;
				case 'emergency':
					data = EmergencyJson;
					break;
				case 'difficult':
					data = DifficultJson;
					break;
				case 'guarantee':
					data = GuaranteeJson;
					break;
			}

			data && data.forEach(item =>{
				if(item.value == val) {
					label = item.label;
				}
			});
			return label;
		},
		formatCommission(val, index, type) {
			if(!val || val == 0) {
				return '请输入...';
			}else {
				if(type == 0) {
					return val + '%'
				}else {
					return val + 'K'
				}
			}

		},
		formatNull(val) {
			if(!val && (val === '' || val === null || val === undefined) || val === '0') {
				return '请输入...'
			}
			val = val.replace(/<br\/>/g, '');
			return val;
		},
		formatNullAnalyse(obj) {
			let result = '';
			let rank = (obj.expectedRank === null || obj.expectedRank === undefined || !obj.expectedRank) ? '' : obj.expectedRank;
			let company = (obj.targetCompany === null || obj.targetCompany === undefined || !obj.targetCompany) ? '' : obj.targetCompany;
			let analysis = (obj.analysis === null || obj.analysis === undefined || !obj.analysis) ? '' : obj.analysis;
			result = (rank + company + analysis).length > 0 ? (rank + company + analysis) : '请输入...';
			result = result.replace(/<br\/>/g, '');
			return result;
		},
        formatRank(val) {
		    if(val) {
		        return val;
            }
		    return '——';
        }
	},
	computed: {
		experienceData() {
			return ExpJson;
		},
		degreeData() {
			return DegreeJson;
		},
		emergencyData() {
			return EmergencyJson;
		},
		difficultData() {
			return DifficultJson;
		},
		guaranteeData() {
			return GuaranteeJson;
		}
	},
	methods: {
		handleEnter(type, index, e) {
			if(type == 'recruitingCount') {
				if(e.target.value < 1 || e.target.value > 99) {
					shortTips('招聘人数的范围在1-99之间');
					setTimeout(() =>{
						e.target.value = '';
						this.job[type] = '';
					}, 24);
					return ;
				}else {
                    this.$emit('show-next-box', 'recruitingCountEdit', index);
                }
			}
			this.job[type] = e.target.value;
			this.job[type + 'Edit'] = false;

			// if(type === 'expectedRank') {
			//     this.handleMatchSalary();
            // }
		},
		handleEdit(type) {
			this.$set(this.job, type + 'Edit', true);
			if(type === 'salary') {
				this.minSalaryInput = this.job.minSalary;
				this.maxSalaryInput = this.job.maxSalary;
			} else if(type === 'analysis') {
				this.expectedRankInput = this.job.expectedRank;
				this.targetCompanyInput = this.job.targetCompany;
				this.analysisInput = this.job.analysis;
			} else {
				this[type + 'Input'] = this.job[type];
			}
		},
		handleBlur(ev, type) {
			if(type == 'recruitingCount') {
				if(ev.target.value < 1 || ev.target.value > 99) {
					shortTips('招聘人数的范围在1-99之间');
					setTimeout(() =>{
						ev.target.value = '';
						this.job[type] = '';
					}, 24);
				}
			}
			this.job[type] = ev.target.value;
			this.job[type + 'Edit'] = false;

			if(type === 'expectedRank') {
                this.handleMatchSalary();
            }
		},
		handleRecruitInput(val, type) {
			if((type === 'expectedRank' && (!val || !this.job.recruitingCount)) || (type === 'recruitingCount' && !val)) {
				this[type + 'Input'] = "";
			}
		},
		handleSave(type, index, e) {
			if(type === 'analysis') {
				let rank = this.expectedRankInput,
					firm = this.targetCompanyInput,
					more = this.analysisInput;
				if(!rank && !firm && !more) {
					shortTips('请至少选择一项填写');
				} else {
					this.job.expectedRank = rank;
					this.job.targetCompany = firm;
					this.job.analysis = more;
					this.$set(this.job, type + 'Edit', false);
					this.$set(this.job, type + 'Visible', false);
					this.handleMatchSalary();
				}
				return;
			} else {
				// if(type === 'commissionValue') {
				// 	this.job.commissionValue = 10
				// }
				if(this.job[type + 'Edit']) {
					this.job[type] = this[type + 'Input'];
				}
				this.$set(this.job, type + 'Edit', false);
				this.$set(this.job, type + 'Visible', false);
			}
		},
		// handleCommissionSave(index, e) {
		// 	let val = e.target.parentNode.parentNode.querySelector('input').value;
		// 	if(this.job.commissionType == 0) {
		// 		if(val > 100) {
		// 			val = 100;
		// 		}
		// 	}
		// 	this.job.commissionValue = val;
		// 	this.$set(this.job, 'commissionVisible', false)
		// },
		// handleCommissionEnter(index, e) {
		// 	let val = e.target.value;
		// 	this.$set(this.job, 'commissionValue', val);
		// 	this.$set(this.job, 'commissionVisible', false);
		// },
		handleSalary(type, index, e) {
			let value = e.target.value;
			if(type === 'minSalary') {
				if(!this.job.minSalaryErr) {
					e.target.parentNode.parentNode.querySelectorAll('input')[1].focus();
					this.$set(this.job, type, Number(value));
				}
			}else if(type === 'maxSalary') {
				if(!this.job.maxSalaryErr && !this.job.minSalaryErr) {
					this.$set(this.job, type, Number(value));
					this.$set(this.job, 'maxSalaryVisible', false);
                    this.$emit('show-next-box', 'maxSalaryVisible', index);
				}else {
					shortTips('薪资输入不合法');
				}
			}
		},
		getCityInfo(array) {
			if(!array) {
				return ''
			}
			let province = ProvinceJson.province;
			let city = array.map((item, index) =>{
				for(let p = 0; p < province.length; p++) {
					let child = province[p].children;

					for(let c = 0; c < child.length; c++) {
						if(item == child[c].value) {
                            return child[c].fullLabel || child[c].label;
						}
					}
				}
			})
			return city && city.join(',');
		},
		handleSelect(type, val, index) {
			this.job[type] = val;
		},
		// handleToggleType(type, index) {
		// 	this.$set(this.job, 'commissionValue', 0);
		// 	this.job.commissionType = type === 'rate' ? 0 : 1;
		// },
		handleLocationSave(param) {
            if(param.applyAll) {
                this.$emit('apply-location-all', param);
            }else {
                this.$set(this.job, 'locations', param.value);
            }
            this.$set(this.job, 'locationsVisible', false);
		},
		handlePopHide(type) {
			this.$set(this.job, type + 'Edit', false);
		},
		handleJobCheck(value) {
			this.$emit('update-check-status', {index: this.index, value: value})
		},
		handleValidateData() {
			let valid = true;
			let err = '请输入... 框为必填字段';
			let publishJob = this.jobData.map((item, index) =>{
				if(item.checked) {
					return item;
				}
			});
			publishJob = publishJob.filter((item, index) =>{
				return item != null && item != undefined
			});
			if(publishJob.length == 0) {
				shortTips('请选择要发布的职位...');
				return false;
			}
			publishJob.forEach((item, index) =>{
				Object.keys(item).forEach(key =>{
					if(MapTitle.hasOwnProperty(key) && (item[key] === null || item[key] === '') && (key != 'analysis' && key != 'targetCompany' && key != 'expectedRank')) {
						valid = false;
						err = '请输入' + MapTitle[key];
					}

					if(key == 'analysis' || key == 'targetCompany' || key == 'expectedRank') {
						if(!this.handleValidAnalyse(item)) {
							err = '请输入职位分析';
							valid = false
						}
					}

					// if(key == 'commissionValue' && item.commissionValue == 0) {
					// 	valid = false;
					// 	err = '请输入签约佣金';
					// }

					if(!item.minSalary || !item.maxSalary) {
						valid = false;
						err = '请输入月薪';
					}

					if(key == 'recruitingCount' && item.recruitingCount < 1) {
						valid = false;
						err = '请输入招聘人数';
					}
				})
			});
			if(!valid) {
				shortTips(err);
				return false;
			}else {
				this.handlePublish(publishJob);
			}
		},
		handleValidAnalyse(job) {
			let result = false;
			if(job.expectedRank || job.targetCompany || job.analysis) {
				result = true;
			}
			return result;
		},
		handlePublish(jobArr) {
			this.$emit('job-publish', jobArr);
		},
		handleSalaryInput(val, type) {
			if(isNaN(Number(val))) {
				this.$set(this.job, type + 'Err', '薪资必须为数字格式');
				setTimeout(() => {
					val = '';
				}, 24)
				return false;
			}
			if(val > 999) {
				this.$set(this.job, type + 'Err', '薪资需在1-999之间');
				setTimeout(() => {
					val = val.slice(0, 3);
				}, 24)
				return false;
			}
			if(val <= 0) {
				this.$set(this.job, type + 'Err', '薪资需在1-999之间');
				setTimeout(() => {
					val = '';
				}, 24)
				return false;
			}
			if(val <= 999 && val > 0) {
				this.$set(this.job, type + 'Err', '');
			}
			if(type === 'maxSalary') {
				if(Number(val) < this.minSalaryInput) {
					this.$set(this.job, 'maxSalaryErr', '需高于最低薪资');
					return false;
				}else {
					this.$set(this.job, 'maxSalaryErr', '');
				}
			}
			if(type === 'minSalary') {
				if(Number(val) > this.maxSalaryInput) {
					this.$set(this.job, 'maxSalaryErr', '需高于最低薪资');
				}else {
					this.$set(this.job, 'maxSalaryErr', '');
				}
			}
		},
		handleAnalyseClass(job) {
			return !(job.expectedRank || job.targetCompany || job.analysis);
		},
		handleFilterSalary(index) {
			let min = this.job.minSalary;
			let max = this.job.maxSalary;
			let result = null;
			if(min && max) {
				result =  min + ' - ' + max + 'K'
			}
			if(!min && max) {
				result =  max + 'K';
			}
			if(!max && min) {
				result = min + 'K';
			}
			if(!max && !min) {
				result = '请输入...';
			}
			return result;
		},
		handleCommissionInput(index, e) {
			if(this.job.commissionType == 0) {
				if(e.target.value > 100) {
					setTimeout(() =>{
						e.target.value = 100;
					}, 24);
					return ;
				}else if(e.target.value < 0) {
					setTimeout(() =>{
						e.target.value = 0;
					}, 24);
					return ;
				}
			}else {
				if(e.target.value > 999) {
					setTimeout(() =>{
						e.target.value = 999;
					}, 24);
					return ;
				}else if(e.target.value < 0) {
					setTimeout(() =>{
						e.target.value = 0;
					}, 24);
					return ;
				}
			}
		},
		handleTransBr(val, type) {
			this[type + 'Input'] = val ? val.replace(/<br\/>/g, '\n') : val;
		},
        handleMatchSalary() {
		    let _self = this;

            if(this.job.expectedRank && !(this.job.maxSalary || this.job.minSalary)) {
				jobCreateMultiService.caculateGradeAndSalary({
                    expectedRank: this.job.expectedRank,
                    batchNo: this.batchNo
				}).then(res => {
					this.job.customerGrades = res.customerGrades;
					this.job.minSalary = res.minSalary;
					this.job.maxSalary = res.maxSalary;
				})
                // request('/Headhunting/JobsBatchImport/CaculateGradeAndSalary', {
                // }, 'get', true, true).then(res =>{
                //     if(res.succeeded) {
                //     }else {
                //         shortTips(errorMessage(res));
                //     }
                // }).catch(err =>{
                //     shortTips('获取标准职级错误');
                // })
            }
        }
	},
}
</script>

<style lang="scss" scope>
.salary-popover.el-popover {
	.item-salary-edit {
		.el-input__suffix {
			line-height: 36px;
		}
	}
}
</style>