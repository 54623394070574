<template>
    <div class="step-process">
        <div class="step-header">
            <span>批量创建职位</span>
            <!-- <span>（创建公司内部职位，你的同事可以接单哦～）</span> -->
            <span class="step-tip"><i class="el-icon-info"></i>创建公司内部职位，你的同事可以接单哦</span>
        </div>
        <div class="step-content">
            <div class="step-bar">
                <div v-bind:class="{'bar-icon-0': step == 0, 'bar-icon-1': step > 0}" class="bar-icon"></div>
                <div v-bind:class="{'bar-check-0': step < 2, 'bar-check-1': step >= 2}" class="bar-icon"></div>
                <div v-bind:class="{'bar-launch-0': step < 4, 'bar-launch-1': step >= 4}" class="bar-icon"></div>
            </div>
            <div class="step-bar step-bar-process">
                <div v-bind:class="{'bar-dot-step0': step == 0, 'bar-dot-step2': step >= 1}" class="bar-dot bar-dot-1"></div>
                <div class="bar-process" v-bind:class="{'bar-transit': this.step > 1}"></div>
                <div v-bind:class="{'bar-dot-step0': step < 2, 'bar-dot-step1': step == 2, 'bar-dot-step2': step > 2}" class="bar-dot bar-dot-2"></div>
                <div class="bar-process" v-bind:class="{'bar-transit': this.step > 3}"></div>
                <div v-bind:class="{'bar-dot-step0': step < 4, 'bar-dot-step1': step == 14, 'bar-dot-step2': step >= 4}" class="bar-dot bar-dot-3"></div>
            </div>
            <div class="step-bar bar-text">
                <div class="text-item text-item-1">上传Excel</div>
                <div class="text-item text-item-2">检测字段</div>
                <div class="text-item text-item-3">职位信息确认及发布</div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';

export default {
    name: 'step-process',
    props: ['step'],
    data() {
        return {
            msg: 'test'
        }
    },
    created() {

    },
    methods: {

    }
}
</script>

<style lang="scss">
@media screen and (max-width: 1400px) {
    .step-content {
        width: 1000px !important;
    }
}
.step-process {

    .step-header {
        padding-left: 25px;
        // margin-bottom: 35px;
        margin-bottom: 40px;
        height: 60px;
        line-height: 60px;
        border-bottom: 1px solid #EEEEEE;
        span:first-child {
            font-size: 18px;
            color: #666666;
            letter-spacing: 0;
        }

        span:nth-child(2) {
            // padding-left: 10px;
            // font-size: 12px;
            margin-left: 42px;
            font-size: 14px;
            // color: #B9B9B9;
            color: #666666;
            letter-spacing: 0;

            > i {
                font-size: 16px;
                color: #ccc;
                // margin-right: 16px;
                margin-right: 10px;

                &:hover {
                    color: $primary;
                }
            }
        }
    }

    .step-content {
        width: 1200px;
        .step-bar {
            margin: 0 auto 20px;
            width: 645px;
            font-size: 0;

            .bar-icon {
                width: 28px;
                height: 28px;
                display: inline-block;
                background-position: left top;
                background-size: 100%;
                margin-right: 280px;

                &:nth-child(3) {
                    margin-right: 0;
                }
            }

            .bar-icon-0 {
                background: url('~@src/assets/images/jobCreateMulti/icon-excel-default.png') no-repeat;
            }

            .bar-icon-1 {
                background: url('~@src/assets/images/jobCreateMulti/icon-excel-active.png') no-repeat;
            }

            .bar-check-0 {
                background: url('~@src/assets/images/jobCreateMulti/iocn-search-grey.png') no-repeat;
            }

            .bar-check-1 {
                background: url('~@src/assets/images/jobCreateMulti/icon-search-active.png') no-repeat;
            }

            .bar-launch-0 {
                background: url('~@src/assets/images/jobCreateMulti/icon-launch-grey.png') no-repeat;
            }

            .bar-launch-1 {
                background: url('~@src/assets/images/jobCreateMulti/icon-launch-active.png') no-repeat;
            }


            .bar-item {
                background: #ddd;
                height: 2px;
                position: relative;
            }
        }

        .step-bar-process {
            height: 24px;
            position: relative;
            padding-top: 11px;

            .bar-dot {
                display: inline-block;
                width: 24px;
                // height: 16px;
                height: 24px;
                background: url('~@src/assets/images/jobCreateMulti/icon-excel-success.png') no-repeat;
                background-position: center left;
                background-size: 100%;
                position: absolute;
                top: 0;
                // top: 12px;
                bottom: 0;
                margin: auto;

                &-1 {
                    left: 0;
                }

                &-2 {
                    left: 48%;
                }

                &-3 {
                    right: 0;
                }

                &-step0 {
                    background: url('~@src/assets/images/jobCreateMulti/icon-step-init.png') no-repeat;
                    height: 24px;
                    background-size: 100% 100%;
                }

                &-step1 {
                    background: url('~@src/assets/images/jobCreateMulti/icon-step-living.gif') no-repeat;
                    height: 24px;
                    background-size: 100% 100%;
                }
                &-step2 {
                    background: url('~@src/assets/images/jobCreateMulti/icon-step-compelete.png') no-repeat;
                    background-size: 100% 100%;
                    height: 24px;
                }
            }

            .bar-process {
                display: inline-block;
                height: 2px;
                width: 315px;
                background: #ddd;
            }

            .bar-transit {
                background: #38bc9c;
            }
        }

        .bar-text {
            position: relative;
            color: #666;

            .text-item {
                text-align: left;
                font-size: 14px;
                display: inline-block;

                &-1 {
                    width: 80px;
                    margin-right: 215px;
                    text-indent: -17px;
                }

                &-2 {
                    width: 100px;
                    margin-right: 100px
                }

                &-3 {
                    width: 150px;
                    margin-right: 0;
                    position: absolute;
                    right: -77px;
                }
            }
        }
    }
}
</style>
