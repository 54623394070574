var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "item-common item-name" }, [
        _c(
          "div",
          {
            staticClass: "item-name-text",
            class: {
              "update-name-len": !_vm.job.name || _vm.job.name.length < 9,
            },
          },
          [
            _c("el-checkbox", {
              staticClass: "item-checked",
              on: { change: _vm.handleJobCheck },
              model: {
                value: _vm.job.checked,
                callback: function ($$v) {
                  _vm.$set(_vm.job, "checked", $$v)
                },
                expression: "job.checked",
              },
            }),
            !_vm.job.nameEdit
              ? _c(
                  "div",
                  {
                    staticClass: "item-name-result text-ellipsis",
                    class: { "null-class": !_vm.job.name },
                    on: {
                      click: function ($event) {
                        return _vm.handleEdit("name")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("formatNull")(_vm.job.name)) +
                        "\n                "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.job.nameEdit
          ? _c(
              "div",
              { staticClass: "item-input-editing" },
              [
                _c("el-input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  on: { blur: (ev) => _vm.handleBlur(ev, "name") },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleEnter("name", _vm.index, $event)
                    },
                  },
                  model: {
                    value: _vm.nameInput,
                    callback: function ($$v) {
                      _vm.nameInput = $$v
                    },
                    expression: "nameInput",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "el-popover",
        {
          attrs: { placement: "bottom", width: "548", trigger: "click" },
          on: {
            hide: function ($event) {
              return _vm.handlePopHide("description")
            },
          },
          model: {
            value: _vm.job.descriptionVisible,
            callback: function ($$v) {
              _vm.$set(_vm.job, "descriptionVisible", $$v)
            },
            expression: "job.descriptionVisible",
          },
        },
        [
          _c("div", { staticClass: "item-des-wrap" }, [
            _c("div", { staticClass: "item-header-common item-des-header" }, [
              _c("span", [_vm._v("职位描述")]),
              !_vm.job.descriptionEdit
                ? _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleEdit("description")
                        },
                      },
                    },
                    [_vm._v("编辑")]
                  )
                : _vm._e(),
              _vm.job.descriptionEdit
                ? _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleSave("description")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "item-des-edit" }, [
              !_vm.job.descriptionEdit
                ? _c("div", { staticClass: "des-edit-result" }, [
                    _c("pre", {
                      staticClass: "pre-text",
                      domProps: { innerHTML: _vm._s(_vm.job.description) },
                    }),
                  ])
                : _vm._e(),
              _vm.job.descriptionEdit
                ? _c(
                    "div",
                    { staticClass: "des-edit-box" },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 11,
                          type: "textarea",
                          resize: "none",
                          maxlength: 8000,
                          placeholder: "请输入内容",
                        },
                        on: {
                          input: (val) => _vm.handleTransBr(val, "description"),
                        },
                        model: {
                          value: _vm.descriptionInput,
                          callback: function ($$v) {
                            _vm.descriptionInput = $$v
                          },
                          expression: "descriptionInput",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "item-common item-des",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c(
                "div",
                {
                  staticClass: "des-text position-absolut text-ellipsis",
                  class: {
                    "null-class": !_vm.job.description,
                    "update-des-len":
                      !_vm.job.description || _vm.job.description.length < 13,
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm._f("formatNull")(_vm.job.description)) +
                      "\n                "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "item-common item-grade" }, [
        !_vm.job.expectedRankEdit
          ? _c(
              "div",
              {
                staticClass: "item-grade-text",
                on: {
                  click: function ($event) {
                    return _vm.handleEdit("expectedRank")
                  },
                },
              },
              [
                _c("div", { staticClass: "grade-result" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm._f("formatRank")(_vm.job.expectedRank)) +
                      "\n                "
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm.job.expectedRankEdit
          ? _c(
              "div",
              { staticClass: "item-input-editing" },
              [
                _c("el-input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  on: {
                    blur: (ev) => _vm.handleBlur(ev, "expectedRank"),
                    input: (val) => _vm.handleRecruitInput(val, "expectedRank"),
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleEnter("expectedRank", _vm.index, $event)
                    },
                  },
                  model: {
                    value: _vm.expectedRankInput,
                    callback: function ($$v) {
                      _vm.expectedRankInput = $$v
                    },
                    expression: "expectedRankInput",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom",
            width: "308",
            "popper-class": "salary-popover",
            trigger: "click",
          },
          on: {
            show: function ($event) {
              return _vm.handleEdit("salary")
            },
            hide: function ($event) {
              return _vm.handlePopHide("salary")
            },
          },
          model: {
            value: _vm.job.maxSalaryVisible,
            callback: function ($$v) {
              _vm.$set(_vm.job, "maxSalaryVisible", $$v)
            },
            expression: "job.maxSalaryVisible",
          },
        },
        [
          _c("div", { staticClass: "item-salary-edit" }, [
            _vm.job.salaryEdit
              ? _c(
                  "div",
                  { staticClass: "salary-edit-input" },
                  [
                    _c(
                      "el-input",
                      {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        class: { "valid-salary-err": _vm.job.minSalaryErr },
                        attrs: { name: "minSalary", type: "number" },
                        on: {
                          blur: (ev) => _vm.handleBlur(ev, "minSalary"),
                          input: (val) =>
                            _vm.handleSalaryInput(val, "minSalary"),
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleSalary(
                              "minSalary",
                              _vm.index,
                              $event
                            )
                          },
                        },
                        model: {
                          value: _vm.minSalaryInput,
                          callback: function ($$v) {
                            _vm.minSalaryInput = $$v
                          },
                          expression: "minSalaryInput",
                        },
                      },
                      [_c("template", { slot: "suffix" }, [_vm._v("K  ")])],
                      2
                    ),
                    _c("span", { staticClass: "salary-space" }, [_vm._v("——")]),
                    _c(
                      "el-input",
                      {
                        class: { "valid-salary-err": _vm.job.maxSalaryErr },
                        attrs: { name: "maxSalary", type: "number" },
                        on: {
                          blur: (ev) => _vm.handleBlur(ev, "maxSalary"),
                          input: (val) =>
                            _vm.handleSalaryInput(val, "maxSalary"),
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleSalary(
                              "maxSalary",
                              _vm.index,
                              $event
                            )
                          },
                        },
                        model: {
                          value: _vm.maxSalaryInput,
                          callback: function ($$v) {
                            _vm.maxSalaryInput = $$v
                          },
                          expression: "maxSalaryInput",
                        },
                      },
                      [_c("template", { slot: "suffix" }, [_vm._v("K  ")])],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.job.minSalaryErr || _vm.job.maxSalaryErr
              ? _c("div", { staticClass: "error-tip" }, [
                  _c("div", { staticClass: "error-tip-item" }, [
                    _vm._v(_vm._s(_vm.job.minSalaryErr)),
                  ]),
                  _c("div", { staticClass: "error-tip-item" }, [
                    _vm._v(_vm._s(_vm.job.maxSalaryErr)),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "item-common item-salary",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "item-salary-text position-absolut text-ellipsis",
                  class: {
                    "null-class": !_vm.job.maxSalary && !_vm.job.minSalary,
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.handleFilterSalary(_vm.index)) +
                      "\n                "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-popover",
        {
          attrs: { placement: "bottom", width: "548", trigger: "click" },
          on: {
            hide: function ($event) {
              return _vm.handlePopHide("analysis")
            },
          },
          model: {
            value: _vm.job.analysisVisible,
            callback: function ($$v) {
              _vm.$set(_vm.job, "analysisVisible", $$v)
            },
            expression: "job.analysisVisible",
          },
        },
        [
          _c("div", { staticClass: "item-analyse-wrap" }, [
            _c(
              "div",
              { staticClass: "item-header-common item-analyse-header" },
              [
                _c("span", [_vm._v("职位分析")]),
                _vm._v("（请至少选择一项填写）\n                    "),
                !_vm.job.analysisEdit
                  ? _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit("analysis")
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    )
                  : _vm._e(),
                _vm.job.analysisEdit
                  ? _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.handleSave("analysis")
                          },
                        },
                      },
                      [_vm._v("保存")]
                    )
                  : _vm._e(),
              ]
            ),
            _c("div", { staticClass: "item-analysis-edit" }, [
              !_vm.job.analysisEdit
                ? _c("div", { staticClass: "item-analyse-result" }, [
                    _vm.job.expectedRank
                      ? _c(
                          "div",
                          { staticClass: "analysis-item analysis-intension" },
                          [
                            _c("span", [_vm._v("职级：")]),
                            _c("pre", {
                              staticClass: "pre-text",
                              domProps: {
                                innerHTML: _vm._s(_vm.job.expectedRank),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm.job.targetCompany
                      ? _c(
                          "div",
                          { staticClass: "analysis-item analysis-company" },
                          [
                            _c("span", [_vm._v("目标公司：")]),
                            _c("pre", {
                              staticClass: "pre-text",
                              domProps: {
                                innerHTML: _vm._s(_vm.job.targetCompany),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "analysis-item analysis-more" }, [
                      _c("span", [_vm._v("其他：")]),
                      _c("pre", {
                        staticClass: "pre-text",
                        domProps: { innerHTML: _vm._s(_vm.job.analysis) },
                      }),
                    ]),
                  ])
                : _vm._e(),
              _vm.job.analysisEdit
                ? _c("div", { staticClass: "analyse-edit-box" }, [
                    _c(
                      "div",
                      { staticClass: "edit-box-item edit-expect-rank" },
                      [
                        _c("span", { staticClass: "sub-title" }, [
                          _vm._v("职级："),
                        ]),
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            maxlength: 200,
                            resize: "none",
                            placeholder: "请输入内容",
                          },
                          on: {
                            input: (val) =>
                              _vm.handleTransBr(val, "expectedRank"),
                          },
                          model: {
                            value: _vm.expectedRankInput,
                            callback: function ($$v) {
                              _vm.expectedRankInput = $$v
                            },
                            expression: "expectedRankInput",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "edit-box-item edit-expect-firm" },
                      [
                        _c("span", { staticClass: "sub-title" }, [
                          _vm._v("目标公司："),
                        ]),
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 2,
                            resize: "none",
                            maxlength: 200,
                            placeholder: "请输入内容",
                          },
                          on: {
                            input: (val) =>
                              _vm.handleTransBr(val, "targetCompany"),
                          },
                          model: {
                            value: _vm.targetCompanyInput,
                            callback: function ($$v) {
                              _vm.targetCompanyInput = $$v
                            },
                            expression: "targetCompanyInput",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "edit-box-item edit-expect-more" },
                      [
                        _c("span", { staticClass: "sub-title" }, [
                          _vm._v("其他："),
                        ]),
                        _c(
                          "div",
                          { staticClass: "add-info-text" },
                          [
                            _c("div", { staticClass: "info-content" }, [
                              _vm._v(
                                "如新增替补原因、汇报对象、团队情况、面试流程、薪资福利"
                              ),
                            ]),
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                maxlength: 1600,
                                placeholder: "请输入内容",
                                rows: 6,
                                resize: "none",
                              },
                              on: {
                                input: (val) =>
                                  _vm.handleTransBr(val, "analysis"),
                              },
                              model: {
                                value: _vm.analysisInput,
                                callback: function ($$v) {
                                  _vm.analysisInput = $$v
                                },
                                expression: "analysisInput",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "item-common item-analysis",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c(
                "div",
                {
                  staticClass: "analysis-text position-absolut text-ellipsis",
                  class: {
                    "null-class": _vm.handleAnalyseClass(_vm.job),
                    "update-analysis-len":
                      (
                        _vm.job.expectedRank +
                        _vm.job.targetCompany +
                        _vm.job.analysis
                      ).length < 14,
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm._f("formatNullAnalyse")(_vm.job)) +
                      "\n                "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-popover",
        {
          attrs: { placement: "bottom", width: "548", trigger: "click" },
          model: {
            value: _vm.job.locationsVisible,
            callback: function ($$v) {
              _vm.$set(_vm.job, "locationsVisible", $$v)
            },
            expression: "job.locationsVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "item-location-edit" },
            [
              _vm.job.locationsVisible
                ? _c("LocationMultiple", {
                    attrs: { citys: _vm.job.locations, index: _vm.index },
                    on: { "location-save": _vm.handleLocationSave },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "item-common item-location",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c(
                "div",
                {
                  staticClass: "location-text position-absolut text-ellipsis",
                  class: {
                    "null-class":
                      !_vm.job.locations || _vm.job.locations.length == 0,
                    "update-location-len":
                      _vm.getCityInfo(_vm.job.locations).length < 4,
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("formatNull")(_vm.getCityInfo(_vm.job.locations))
                      ) +
                      "\n                "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "item-common item-recruiting" }, [
        !_vm.job.recruitingCountEdit
          ? _c(
              "div",
              {
                staticClass: "item-recruiting-text",
                class: { "null-class": !_vm.job.recruitingCount },
                on: {
                  click: function ($event) {
                    return _vm.handleEdit("recruitingCount")
                  },
                },
              },
              [
                _c("div", { staticClass: "recruiting-result" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm._f("formatCount")(_vm.job.recruitingCount)) +
                      "\n                "
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm.job.recruitingCountEdit
          ? _c(
              "div",
              { staticClass: "item-input-editing" },
              [
                _c("el-input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  on: {
                    blur: (ev) => _vm.handleBlur(ev, "recruitingCount"),
                    input: (val) =>
                      _vm.handleRecruitInput(val, "recruitingCount"),
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleEnter(
                        "recruitingCount",
                        _vm.index,
                        $event
                      )
                    },
                  },
                  model: {
                    value: _vm.recruitingCountInput,
                    callback: function ($$v) {
                      _vm.recruitingCountInput = $$v
                    },
                    expression: "recruitingCountInput",
                  },
                }),
                _c("span", { staticClass: "count-tip" }, [_vm._v("人")]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "job-drop-item drop-item-year" }, [
        _c(
          "div",
          { staticClass: "drop-item-select" },
          [
            _c(
              "el-dropdown",
              { attrs: { trigger: "click", placement: "bottom" } },
              [
                _c("div", { staticClass: "select-result" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatCommon")(
                        _vm.job.minYearOfExperience,
                        "year"
                      )
                    )
                  ),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  _vm._l(_vm.experienceData, function (item) {
                    return _c(
                      "el-dropdown-item",
                      {
                        key: item.value,
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleSelect(
                              "minYearOfExperience",
                              item.value,
                              _vm.index
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "job-drop-item drop-item-degree" }, [
        _c(
          "div",
          { staticClass: "drop-item-select" },
          [
            _c(
              "el-dropdown",
              { attrs: { trigger: "click", placement: "bottom" } },
              [
                _c("div", { staticClass: "select-result" }, [
                  _vm._v(
                    _vm._s(_vm._f("formatCommon")(_vm.job.minDegree, "degree"))
                  ),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  _vm._l(_vm.degreeData, function (item) {
                    return _c(
                      "el-dropdown-item",
                      {
                        key: item.value,
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleSelect(
                              "minDegree",
                              item.value,
                              _vm.index
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "job-drop-item drop-item-emergency" }, [
        _c(
          "div",
          { staticClass: "drop-item-select" },
          [
            _c(
              "el-dropdown",
              { attrs: { trigger: "click", placement: "bottom" } },
              [
                _c("div", { staticClass: "select-result" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatCommon")(
                        _vm.job.emergencyDegree,
                        "emergency"
                      )
                    )
                  ),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  _vm._l(_vm.emergencyData, function (item) {
                    return _c(
                      "el-dropdown-item",
                      {
                        key: item.value,
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleSelect(
                              "emergencyDegree",
                              item.value,
                              _vm.index
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "job-drop-item drop-item-difficult" }, [
        _c(
          "div",
          { staticClass: "drop-item-select" },
          [
            _c(
              "el-dropdown",
              { attrs: { trigger: "click", placement: "bottom" } },
              [
                _c("div", { staticClass: "select-result" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatCommon")(
                        _vm.job.difficultDegree,
                        "difficult"
                      )
                    )
                  ),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  _vm._l(_vm.difficultData, function (item) {
                    return _c(
                      "el-dropdown-item",
                      {
                        key: item.value,
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleSelect(
                              "difficultDegree",
                              item.value,
                              _vm.index
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }