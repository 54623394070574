var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "job-edit" },
    [
      _c("div", { staticClass: "job-edit-tip" }, [
        _vm.customerName
          ? _c("span", { staticClass: "customer-label" }, [
              _vm._v("所属公司："),
            ])
          : _vm._e(),
        _vm.customerName
          ? _c("span", { staticClass: "customer-name" }, [
              _vm._v(_vm._s(_vm.customerName)),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "job-edit-wrap" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "job-edit-content" },
          _vm._l(_vm.jobData, function (job, index) {
            return _c("EditChild", {
              key: index,
              staticClass: "edit-content-item",
              attrs: { "batch-no": _vm.batchNo, index: index, job: job },
              on: {
                "apply-location-all": _vm.handleApplyLocation,
                "show-next-box": _vm.handleShowNextBox,
                "update-check-status": _vm.handleJobCheck,
              },
            })
          }),
          1
        ),
      ]),
      _c("div", { staticClass: "job-publish" }, [
        _c(
          "div",
          { staticClass: "job-publish-item job-publish-checkall" },
          [
            _c(
              "el-checkbox",
              {
                on: { change: _vm.handleCheckAll },
                model: {
                  value: _vm.checkAll,
                  callback: function ($$v) {
                    _vm.checkAll = $$v
                  },
                  expression: "checkAll",
                },
              },
              [_vm._v("\n\t\t\t\t\t全选\n\t\t\t\t")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "job-publish-item job-publish-count" }, [
          _vm._v(
            "\n                已选择" +
              _vm._s(_vm.jobPublishCount) +
              "个\n            "
          ),
        ]),
        _c(
          "div",
          { staticClass: "job-publish-item job-publish-operate" },
          [
            _c(
              "el-button",
              {
                staticClass: "job-operate-btn job-operate-salary",
                attrs: { disabled: _vm.jobPublishCount === 0 },
                on: {
                  click: function ($event) {
                    return _vm.showMulTipleDialog("salary")
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\t添加月薪\n\t\t\t\t")]
            ),
            _c(
              "el-button",
              {
                staticClass: "job-operate-btn job-operate-wantednum",
                attrs: { disabled: _vm.jobPublishCount === 0 },
                on: {
                  click: function ($event) {
                    return _vm.showMulTipleDialog("wantednum")
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\t添加招聘人数\n\t\t\t\t")]
            ),
            _c(
              "el-button",
              {
                staticClass: "job-operate-btn job-operate-emergency",
                attrs: { disabled: _vm.jobPublishCount === 0 },
                on: {
                  click: function ($event) {
                    return _vm.showMulTipleDialog("emergency")
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\t修改紧急程度\n\t\t\t\t")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "job-publish-item job-publish-btn",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleValidateData.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\t\t一键发布\n\t\t\t")]
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.multipleDialogTitle,
            "show-close": false,
            visible: _vm.isShowMulTipleDialog,
            "close-on-click-modal": false,
            "close-on-press-modal": false,
            "custom-class": _vm.multipleDialogType
              ? "job-edit-multiple-dialog more-width"
              : "job-edit-multiple-dialog",
          },
        },
        [
          _vm.multipleDialogType
            ? [
                _c("div", { staticClass: "mutiple-edit-title" }, [
                  _vm._v(
                    "\n\t\t\t\t\t你正在批量" +
                      _vm._s(
                        _vm.multipleDialogType === "emergency" ? "修改" : "添加"
                      ) +
                      "\n\t\t\t\t\t"
                  ),
                  _c("span", { staticClass: "text-primary" }, [
                    _vm._v(_vm._s(_vm.multipleEditJobName)),
                  ]),
                  _vm._v("\n\t\t\t\t\t等"),
                  _c("span", { staticClass: "text-warning" }, [
                    _vm._v(_vm._s(_vm.jobPublishCount)),
                  ]),
                  _vm._v(
                    "个职位的" +
                      _vm._s(
                        _vm.multipleDialogType === "salary"
                          ? "月薪"
                          : _vm.multipleDialogType === "emergency"
                          ? "紧急程度"
                          : "招聘人数"
                      ) +
                      "\n\t\t\t\t"
                  ),
                ]),
                _vm.multipleDialogType === "salary"
                  ? _c(
                      "div",
                      { staticClass: "mutiple-edit-item mutiple-edit-salary" },
                      [
                        _c(
                          "el-input",
                          {
                            on: { change: _vm.handleMulEditSalary },
                            model: {
                              value: _vm.multipleEditMinSalary,
                              callback: function ($$v) {
                                _vm.multipleEditMinSalary = $$v
                              },
                              expression: "multipleEditMinSalary",
                            },
                          },
                          [_c("template", { slot: "suffix" }, [_vm._v("K  ")])],
                          2
                        ),
                        _c("span", { staticStyle: { margin: "0 5px" } }, [
                          _vm._v("--"),
                        ]),
                        _c(
                          "el-input",
                          {
                            on: { change: _vm.handleMulEditSalary },
                            model: {
                              value: _vm.multipleEditMaxSalary,
                              callback: function ($$v) {
                                _vm.multipleEditMaxSalary = $$v
                              },
                              expression: "multipleEditMaxSalary",
                            },
                          },
                          [_c("template", { slot: "suffix" }, [_vm._v("K  ")])],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.multipleDialogType === "wantednum"
                  ? _c(
                      "div",
                      {
                        staticClass: "mutiple-edit-item mutiple-edit-wantednum",
                      },
                      [
                        _c(
                          "el-input",
                          {
                            on: { change: _vm.handleMulEditWantedNum },
                            model: {
                              value: _vm.multipleEditWantedNum,
                              callback: function ($$v) {
                                _vm.multipleEditWantedNum = $$v
                              },
                              expression: "multipleEditWantedNum",
                            },
                          },
                          [
                            _c("template", { slot: "suffix" }, [
                              _vm._v("人  "),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.multipleDialogType === "emergency"
                  ? _c(
                      "div",
                      {
                        staticClass: "mutiple-edit-item mutiple-edit-emergency",
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: { change: _vm.handleMulEditEmergency },
                            model: {
                              value: _vm.multipleEditEmergency,
                              callback: function ($$v) {
                                _vm.multipleEditEmergency = $$v
                              },
                              expression: "multipleEditEmergency",
                            },
                          },
                          [
                            _c(
                              "el-radio-button",
                              {
                                staticClass: "red-fill",
                                attrs: { label: "1" },
                              },
                              [_vm._v("紧急")]
                            ),
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("不紧急"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.multipleEditError
                  ? _c("div", { staticClass: "mutiple-edit-error" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.multipleEditTip) +
                          "\n\t\t\t\t"
                      ),
                    ])
                  : _vm._e(),
              ]
            : _c("div", { staticClass: "mutiple-edit-tip" }, [
                _vm._v(
                  "\n\t\t\t\t该表格共有" +
                    _vm._s(_vm.jobData.length) +
                    "个职位，你仅勾选" +
                    _vm._s(_vm.jobPublishCount) +
                    "个职位进行发布，确定继续吗？\n\t\t\t"
                ),
              ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.multipleDialogType
                ? [
                    _c("el-button", { on: { click: _vm.cancelHandle } }, [
                      _vm._v("取消"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.confirmHandle },
                      },
                      [_vm._v("确定")]
                    ),
                  ]
                : [
                    _c("el-button", { on: { click: _vm.confirmPublish } }, [
                      _vm._v("继续发布"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.cancelHandle },
                      },
                      [_vm._v("重新选择")]
                    ),
                  ],
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "job-edit-header" }, [
      _c("div", { staticClass: "job-edit-item job-header-name" }, [
        _c("span", [_vm._v("职位"), _c("br"), _vm._v("名称"), _c("br")]),
      ]),
      _c("div", { staticClass: "job-edit-item job-header-des" }, [
        _c("span", [_vm._v("职位"), _c("br"), _vm._v("描述"), _c("br")]),
      ]),
      _c("div", { staticClass: "job-edit-item job-header-grade" }, [
        _c("span", [_vm._v("职级 "), _c("br"), _vm._v("(选填)")]),
      ]),
      _c("div", { staticClass: "job-edit-item job-header-salary" }, [
        _c("span", [_vm._v("月薪")]),
      ]),
      _c("div", { staticClass: "job-edit-item job-header-analyse" }, [
        _c("span", [_vm._v("职位"), _c("br"), _vm._v("分析"), _c("br")]),
      ]),
      _c("div", { staticClass: "job-edit-item job-header-location" }, [
        _c("span", [_vm._v("工作"), _c("br"), _vm._v("地区"), _c("br")]),
      ]),
      _c("div", { staticClass: "job-edit-item job-header-num" }, [
        _c("span", [_vm._v("招聘"), _c("br"), _vm._v("人数"), _c("br")]),
      ]),
      _c("div", { staticClass: "job-edit-item job-header-year" }, [
        _c("span", [_vm._v("工作"), _c("br"), _vm._v("年限"), _c("br")]),
      ]),
      _c("div", { staticClass: "job-edit-item job-header-degree" }, [
        _c("span", [_vm._v("最低"), _c("br"), _vm._v("学历"), _c("br")]),
      ]),
      _c("div", { staticClass: "job-edit-item job-header-emergency" }, [
        _c("span", [_vm._v("紧急"), _c("br"), _vm._v("程度"), _c("br")]),
      ]),
      _c("div", { staticClass: "job-edit-item job-header-difficult" }, [
        _c("span", [_vm._v("难易"), _c("br"), _vm._v("程度"), _c("br")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }