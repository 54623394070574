<template>
	<div class="job-edit">
		<div class="job-edit-tip">
			<!-- <span class="tip-text">TIPS：</span>单击单元格即可编辑，
			<span class="tip-box tip-fill"></span>已检测填充项；
			<span class="tip-box tip-supplement"></span>已检测填充项；
			<span class="tip-box tip-default"></span>默认填充项； -->
			<span class="customer-label" v-if="customerName">所属公司：</span>
			<span class="customer-name" v-if="customerName">{{customerName}}</span>
		</div>
		<div class="job-edit-wrap">
			<div class="job-edit-header">
				<div class="job-edit-item job-header-name">
					<span>职位<br />名称<br /></span>
				</div>
				<div class="job-edit-item job-header-des">
					<span>职位<br />描述<br /></span>
				</div>
                <div class="job-edit-item job-header-grade">
                    <span>职级 <br />(选填)</span>
                </div>
				<div class="job-edit-item job-header-salary">
					<span>月薪</span>
				</div>
				<div class="job-edit-item job-header-analyse">
					<span>职位<br />分析<br /></span>
				</div>
				<div class="job-edit-item job-header-location">
					<span>工作<br />地区<br /></span>
				</div>
				<div class="job-edit-item job-header-num">
					<span>招聘<br />人数<br /></span>
				</div>
				<div class="job-edit-item job-header-year">
					<span>工作<br />年限<br /></span>
				</div>
				<div class="job-edit-item job-header-degree">
					<span>最低<br />学历<br /></span>
				</div>
				<div class="job-edit-item job-header-emergency">
					<span>紧急<br />程度<br /></span>
				</div>
				<div class="job-edit-item job-header-difficult">
					<span>难易<br />程度<br /></span>
				</div>
				<!--<div class="job-edit-item job-header-commission">-->
					<!--<span>签约<br />佣金<br /></span>-->
				<!--</div>-->
				<!--<div class="job-edit-item job-header-guarantee">-->
					<!--<span>保证期</span>-->
				<!--</div>-->
			</div>
			<div class="job-edit-content">
                <EditChild
					class="edit-content-item"
					@apply-location-all="handleApplyLocation"
					:batch-no="batchNo"
					@show-next-box="handleShowNextBox"
					v-on:update-check-status="handleJobCheck"
					v-for="(job, index) in jobData"
					:index="index"
					:key="index"
					:job="job"
				/>
			</div>
		</div>
		<div class="job-publish">
			<div class="job-publish-item job-publish-checkall">
				<el-checkbox
					v-model="checkAll"
					@change="handleCheckAll">
					全选
				</el-checkbox>
			</div>
            <div class="job-publish-item job-publish-count">
                已选择{{jobPublishCount}}个
            </div>
			<div class="job-publish-item job-publish-operate">
				<el-button
					class="job-operate-btn job-operate-salary"
					@click="showMulTipleDialog('salary')"
					:disabled="jobPublishCount === 0">
					添加月薪
				</el-button>
				<el-button
					class="job-operate-btn job-operate-wantednum"
					@click="showMulTipleDialog('wantednum')"
					:disabled="jobPublishCount === 0">
					添加招聘人数
				</el-button>
				<el-button
					class="job-operate-btn job-operate-emergency"
					@click="showMulTipleDialog('emergency')"
					:disabled="jobPublishCount === 0">
					修改紧急程度
				</el-button>
			</div>
			<div class="job-publish-item job-publish-btn" @click.stop="handleValidateData">
				一键发布
			</div>
		</div>
		<el-dialog
			:title="multipleDialogTitle"
			:show-close="false"
			:visible="isShowMulTipleDialog"
			:close-on-click-modal="false"
			:close-on-press-modal="false"
			:custom-class="multipleDialogType ? 'job-edit-multiple-dialog more-width' : 'job-edit-multiple-dialog'"
		>
			<template v-if="multipleDialogType">
				<div class="mutiple-edit-title">
					你正在批量{{multipleDialogType === 'emergency' ? '修改' : '添加'}}
					<span class="text-primary">{{multipleEditJobName}}</span>
					等<span class="text-warning">{{jobPublishCount}}</span>个职位的{{multipleDialogType === 'salary' ? '月薪' : (multipleDialogType === 'emergency' ? '紧急程度' : '招聘人数')}}
				</div>
				<div class="mutiple-edit-item mutiple-edit-salary" v-if="multipleDialogType === 'salary'">
					<el-input v-model="multipleEditMinSalary" @change="handleMulEditSalary">
						<template slot="suffix">K&nbsp;&nbsp;</template>
					</el-input>
					<span style="margin: 0 5px;">--</span>
					<el-input v-model="multipleEditMaxSalary" @change="handleMulEditSalary">
						<template slot="suffix">K&nbsp;&nbsp;</template>
					</el-input>
				</div>
				<div class="mutiple-edit-item mutiple-edit-wantednum" v-if="multipleDialogType === 'wantednum'">
					<el-input v-model="multipleEditWantedNum" @change="handleMulEditWantedNum">
						<template slot="suffix">人&nbsp;&nbsp;</template>
					</el-input>
				</div>
				<div class="mutiple-edit-item mutiple-edit-emergency" v-if="multipleDialogType === 'emergency'">
					<el-radio-group v-model="multipleEditEmergency" @change="handleMulEditEmergency">
						<el-radio-button label="1" class="red-fill">紧急</el-radio-button>
						<el-radio-button label="0">不紧急</el-radio-button>
					</el-radio-group>
				</div>
				<div class="mutiple-edit-error" v-if="multipleEditError">
					{{multipleEditTip}}
				</div>
			</template>
			<div class="mutiple-edit-tip" v-else>
				该表格共有{{jobData.length}}个职位，你仅勾选{{jobPublishCount}}个职位进行发布，确定继续吗？
			</div>
			<span slot="footer" class="dialog-footer">
				<template v-if="multipleDialogType">
					<el-button @click="cancelHandle">取消</el-button>
					<el-button type="primary" @click="confirmHandle">确定</el-button>
				</template>
				<template v-else>
					<el-button @click="confirmPublish">继续发布</el-button>
					<el-button type="primary" @click="cancelHandle">重新选择</el-button>
				</template>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import LocationMultiple from '../component/LocationMultiple.vue';
import EditChild from '../component/EditChild.vue';
import ProvinceJson from '#/js/config/provinceThreeData.json';
import DegreeJson from '../config/degreesArr.json';
import DifficultJson from '../config/difficultyArr.json';
import EmergencyJson from '../config/emergencyDegreeArr.json';
import ExpJson from '../config/experienceArr.json';
import GuaranteeJson from '../config/guaranteePeriodArr.json';

const MapTitle = {
	name: '职位名称',
	description: '职位描述',
	minSalary: '最小月薪',
	maxSalary: '最大月薪',
	analysis: '职位分析',
	locations: '工作地区',
	recruitingCount: '招聘人数',
	minYearOfExperience: '工作年限',
	minDegree: '最低学历',
	emergencyDegree: '紧急程度',
	difficultDegree: '难易程度',
	commissionValue: '签约佣金',
	guaranteePeriod: '保证期'
}

export default {
	name: 'JobEdit',
	props: {
		jobData: {
			type: Array,
			default: []
		},
		customerName: {
			type: String,
			default: ''
		},
        batchNo: {
		    type: String,
            default: ''
        }
	},
	components: {
		LocationMultiple,
		EditChild
	},
	data() {
		return {
			checkAll: true,
			multipleDialogType: '',
			isShowMulTipleDialog: false,
			multipleEditMinSalary: "",
			multipleEditMaxSalary: "",
			multipleEditWantedNum: "",
			multipleEditEmergency: "",
			multipleEditError: false,
			multipleEditTip: ""
		}
	},
    mounted() {
        document.getElementsByClassName('job-publish-checkall')[0].click();
    },
    computed: {
        jobPublishCount() {
            let count = 0;
            let list = this.jobData.filter(item =>{
                return item.checked === true;
            });

            count = list.length;

            return count;
		},
		multipleDialogTitle() {
			switch(this.multipleDialogType) {
				case 'salary':
					return '批量添加月薪';
				case 'wantednum':
					return '批量添加招聘人数';
				case 'emergency':
					return '批量修改紧急程度';
				default:
					return '提示';
			}
		},
		multipleEditJobName() {
			let nameList = [];
            this.jobData.forEach(item =>{
                if(item.checked) {
					nameList.push(item.name);
				}
			});
			return nameList.slice(0, 5).join('、');
		}
    },
	methods: {
		handleCheckAll(checkAll) {
			let jobs = this.jobData;
			if(checkAll) {
				jobs.forEach((item, index) =>{
					// Vue.set(this.jobData[index], 'checked', true);
					item.checked = true;
				});
			}else {
				jobs.forEach((item, index) =>{
					// Vue.set(this.jobData[index], 'checked', false);
					item.checked = false;
				});
			}
		},
		handleJobCheck(param) {
			let checkedAll = true;
			this.jobData[param.index].checked = param.value;
			this.jobData.forEach((item, index) =>{
				if(!item.checked) {
					checkedAll = false;
				}
			});

			if(checkedAll) {
				this.checkAll = true;
			}

			if(!param.value) {
				this.checkAll = false;
			}
		},
		handleValidateData() {
			let valid = true;
			let err = '请输入... 框为必填字段';
			let publishJob = this.jobData.filter(item => item.checked);
			if(publishJob.length == 0) {
				shortTips('请选择要发布的职位...');
				return false;
			}
			publishJob.forEach((item, index) =>{
				Object.keys(item).forEach(key =>{
					if(MapTitle.hasOwnProperty(key) && (item[key] === null || item[key] === '') && (key != 'analysis' && key != 'targetCompany' && key != 'expectedRank' && key != 'minDegree')) {
						valid = false;
						err = '请输入' + MapTitle[key];
					}
                    // 验证文件名
                    if(key == 'name' && item.name) {
                        if(!/^[a-zA-Z0-9-\u4e00-\u9fa5()（）\.\#\+\-\s]+$/g.test(item.name)) {
                            err = '职位名称仅支持“-”和“（）”、“()”、“.”、“+”、“#”这六种符号存在。';
							valid = false;
                        }
                        if(/\/|\,|\\|\||\、/g.test(item.name) ||
                        (/.*Java|后端.*/ig.test(item.name) && /.*前端.*/ig.test(item.name)) ||
                        (/.*销售|售前.*/ig.test(item.name) && /.*研发|开发.*/ig.test(item.name)) 
                        ) {
                            err = `不允许多个职位信息混编在一起，请确保一个职位信息创建一个对应系统职位，同时职位名称仅支持“-”和“（）”、“()”这三种符号存在。`
                            valid = false;
                        }
                    }
					if(key == 'analysis' || key == 'targetCompany' || key == 'expectedRank') {
						if(!this.handleValidAnalyse(item)) {
							err = '请输入职位分析';
							valid = false
						}
					}
					if(key == 'commissionValue' && item.commissionValue == 0) {
						valid = false;
						err = '请输入签约佣金';
					}
					if(!item.minSalary || !item.maxSalary) {
						valid = false;
						err = '请输入月薪';
					}
					if(key == 'recruitingCount' && item.recruitingCount < 1) {
						valid = false;
						err = '请输入招聘人数';
					}
				})
			});
			if(!valid) {
				shortTips(err);
				return false;
			}else {
				if(publishJob.length < this.jobData.length) {
					this.showMulTipleDialog();
					return false;
				}
				this.handlePublish(publishJob);
			}
		},
		handleValidAnalyse(job) {
			let result = false;
			if(job.expectedRank || job.targetCompany || job.analysis) {
				result = true;
			}
			return result;
		},
		handlePublish(jobArr) {
			this.$emit('job-publish', jobArr);
		},
        handleShowNextBox(type, index){
            let targetIndex = index + 1;
            let total = this.jobData.length;

            if(targetIndex < total) {
                this.$set(this.jobData[targetIndex], type, true);
            }

            return;
        },
        handleApplyLocation(param) {
            this.jobData.forEach((item, index) =>{
                this.$set(item, 'locations', param.value);
            })
		},
		showMulTipleDialog(type) {
			this.multipleDialogType = type;
			this.isShowMulTipleDialog = true;
		},
		handleMulEditSalary() {
			let minSalary = this.multipleEditMinSalary,
				maxSalary = this.multipleEditMaxSalary;
			if(minSalary === "") {
				this.multipleEditError = true;
				this.multipleEditTip = "请填写最低月薪";
			} else if(maxSalary === "") {
				this.multipleEditError = true;
				this.multipleEditTip = "请填写最高月薪";
			} else if(isNaN(Number(minSalary)) || isNaN(Number(maxSalary))) {
				this.multipleEditError = true;
				this.multipleEditTip = "月薪必须为数字格式";
			} else if(minSalary < 1 || minSalary > 999 || maxSalary < 1 || maxSalary > 999) {
				this.multipleEditError = true;
				this.multipleEditTip = "月薪的有效范围是1 ~ 999";
			} else if(Number(minSalary) > Number(maxSalary)) {
				this.multipleEditError = true;
				this.multipleEditTip = "最低月薪不能大于最高月薪";
			} else {
				this.multipleEditError = false;
				this.multipleEditTip = "";
			}
		},
		handleMulEditWantedNum() {
			if(this.multipleEditWantedNum === "") {
				this.multipleEditError = true;
				this.multipleEditTip = "请填写招聘人数";
			} else if(isNaN(Number(this.multipleEditWantedNum))) {
				this.multipleEditError = true;
				this.multipleEditTip = "招聘人数必须为数字格式";
			} else if(this.multipleEditWantedNum < 1 || this.multipleEditWantedNum > 99) {
				this.multipleEditError = true;
				this.multipleEditTip = "招聘人数的有效范围是1 ~ 99";
			} else {
				this.multipleEditError = false;
				this.multipleEditTip = "";
			}
		},
		handleMulEditEmergency() {
			if(this.multipleEditEmergency === "") {
				this.multipleEditError = true;
				this.multipleEditTip = "请选择紧急程度";
			} else {
				this.multipleEditError = false;
				this.multipleEditTip = "";
			}
		},
		cancelHandle() {
			this.isShowMulTipleDialog = false;
			this.multipleEditMinSalary = "";
			this.multipleEditMaxSalary = "";
			this.multipleEditWantedNum = "";
			this.multipleEditEmergency = "";
			this.multipleEditError = false;
			this.multipleEditTip = "";
		},
		confirmHandle() {
			let type = this.multipleDialogType,
				json = {};
			if(type === 'salary') {
				this.handleMulEditSalary();
			} else if(type === 'wantednum') {
				this.handleMulEditWantedNum();
			} else if(type === 'emergency') {
				this.handleMulEditEmergency();
			}
			if(this.multipleEditError) {
				return;
			}
			switch(type) {
				case 'salary':
					json.minSalary = this.multipleEditMinSalary;
					json.maxSalary = this.multipleEditMaxSalary;
					break;
				case 'wantednum':
					json.recruitingCount = this.multipleEditWantedNum;
					break;
				case 'emergency':
					json.emergencyDegree = this.multipleEditEmergency;
					break;
			}
			this.$emit('multiple-edit', type, json);
			this.cancelHandle();
		},
		confirmPublish() {
			this.cancelHandle();
			this.handlePublish(this.jobData.filter(item => item.checked));
		}
	},

}
</script>
<style lang="scss">
// @media screen and (max-width: 1400px) {
//     .job-edit {
// 		width: 1000px !important;
// 		&-wrap {
// 			white-space: nowrap;
// 			overflow-x: auto;
// 			overflow-y: hidden;
// 		}
// 		.item-common, .job-drop-item {
// 			border-bottom: 1px solid #ddd;
// 		}
// 	}
// }
	.job-edit {
		// width: 1190px;
		width: 100%;

		.null-class {
			color: #FF6600;
		}

		&-tip {
			color: #b9b9b9;
			font-size: 12px;
			margin: 35px 0 10px;

			.tip-box {
				display: inline-block;
				width: 10px;
				height: 10px;
				border: 1px solid #eee;
				margin-right: 5px;
			}

			.tip-text {
				color: #38bc9c;
			}

			.tip-fill {
				background: #eff9f7;
			}

			.tip-supplement {
				background: #ffecdf;
			}

			.tip-default {
				background: #fff6db;
			}

			.customer-name {
				color: #666;
			}

			.customer-label {
				color: #38bc9c;
			}
		}

		&-wrap {
			width: 100%;
			border: 1px solid #ddd;
			// border-right: none;
			border-right: 1px solid #ddd;

			white-space: nowrap;
			overflow-x: auto;
			overflow-y: hidden;

			.job-edit-header {
				width: 100%;
				height: 79px;
				color: #444;
				font-size: 0;
				// border-bottom: 1px solid #ddd;
				background-color: #ecf9f6;

				.job-edit-item {
					display: inline-block;
					height: 100%;
					position: relative;
					border-right: 1px solid #ddd;
					position: relative;
					font-size: 14px;
					line-height: 1.5;
					border-bottom: 1px solid #ddd;

					span {
						position: absolute;
					    top: 0;
					    bottom: 0;
					    margin: auto;
					    left: 0;
					    height: 40px;
					    text-align: center;
					    width: 100%;
					}
				}

				.job-header-name {
					// width: 154px;
					min-width: 154px;
					width: 13%;
					height: 100%;
					line-height: 1.5;
					background: #ecf9f6;

					span {
						padding-left: 50px;
						text-align: left;
					}
				}

				.job-header-des {
					// width: 199px;
					min-width: 199px;
					width: 20%;
					background: #ecf9f6;
				}

                .job-header-grade {
                    // width: 135px;
					min-width: 135px;
					width: 10%;
                    vertical-align: top;
                    background: #ecf9f6;

                    span:nth-child(2) {
                        color: #2a2a2a;
                    }
                }

				.job-header-salary {
					// width: 91px;
					min-width: 91px;
					width: 6%;
					/*background: #fff0e6;*/
					background: #ecf9f6;

					span {
						height: 20px;
						left: 0;
					}
				}

				.job-header-analyse {
					// width: 200px;
					min-width: 200px;
					width: 20%;
					/*background: #fff0e6;*/
					background: #ecf9f6;
				}

				.job-header-location {
					// width: 69px;
					min-width: 69px;
					width: 5%;
					/*background: #fff0e6;*/
					background: #ecf9f6;
				}

				.job-header-num {
					// width: 64px;
					min-width: 64px;
					width: 5%;
					/*background: #fff0e6;*/
					background: #ecf9f6;
				}

				.job-header-year {
					// width: 75px;
					min-width: 75px;
					width: 5%;
					/*background: #fef9ea;*/
					background: #ecf9f6;
				}

				.job-header-degree {
					// width: 90px;
					min-width: 90px;
					width: 6%;
					/*background: #fef9ea;*/
					background: #ecf9f6;
				}

				.job-header-emergency {
					// width: 66px;
					min-width: 66px;
					width: 5%;
					/*background: #fef9ea;*/
					background: #ecf9f6;
				}

				.job-header-difficult {
					// width: 45px;
					min-width: 45px;
					width: 5%;
					/*background: #fef9ea;*/
					background: #ecf9f6;

					border-right: none;
				}

				.job-header-commission {
					width: 73px;
					/*background: #fef9ea;*/
					background: #ecf9f6;
				}

				.job-header-guarantee {
					width: 62px;
					/*background: #fef9ea;*/
					background: #ecf9f6;
					border-right: 1px solid transparent;

					span {
						height: 20px;
					}
				}
			}

			.job-edit-content {

				.el-input__inner:focus {
		            border-color: #38bc9c;
		        }

				.edit-content-item {
					width: 100%;
					height: 79px;
					color: #444;
					font-size: 0;
					border-bottom: 1px solid #ddd;

					&:nth-child(odd) {
						background: #fff;
					}

					&:nth-child(even) {
						// background: #f8f8f8;
                        >div, .item-common{
                            background: #f8f8f8;
                        }
					}

					&:last-child {
						border-bottom: 1px solid transparent;
					}

					.item-common {
						display: inline-block;
						height: 100%;
						position: relative;
						border-right: 1px solid #ddd;
						position: relative;
						font-size: 14px;
						line-height: 1.5;
						vertical-align: top;
						cursor: pointer;

						&:hover {
							color: #38bc9c;
						}
					}
					.item-name {
						// width: 154px;
						min-width: 154px;
						width: 13%;
						height: 100%;
						line-height: 1.5;
						position: relative;

						&-text {
						    height: 38px;
							line-height: 38px;
						    position: absolute;
						    top: 0;
						    bottom: 0;
						    margin: auto;

							.item-checked {
								position: absolute;
							    top: 0;
							    bottom: 0;
							    margin: auto;
							    left: 11px;
							    height: 18px;
								line-height: 18px;

								.el-checkbox__input {

									.el-checkbox__inner {
										background: transparent;

										&:hover {
											border-color: #38bc9c;
										}
									}
								}

								.is-checked {
									.el-checkbox__inner{
										background-color: #38bc9c;
										border-color: #38bc9c;
									}
								}

								.is-focus {
									.el-checkbox__inner{
										border-color: #38bc9c;
									}
								}
							}

							.item-name-result {
								width: 105px;
								height: 100%;
								margin-left: 39px;
								cursor: pointer;
							}
						}

						.update-name-len {
							height: 20px;
							line-height: 20px;
						}

						.item-input-editing {
							position: absolute;
						    top: 0;
						    bottom: 0;
						    left: 40px;
						    margin: auto;
						    width: 208px;
						    height: 40px;
						    z-index: 10;

						    .el-input {

						    	.el-input__inner {
						    		width: 208px;
						    		height: 40px;
						    		line-height: 40px;
						    	}
						    }
						}
					}

					.item-des {
						// width: 199px;
						min-width: 199px;
						width: 20%;
						cursor: pointer;

						.des-text {
						    width: 100%;
						    height: 38px;
							line-height: 38px;
						    padding: 0 7px;
						}

						.update-des-len {
							height: 20px;
							line-height: 20px;
						}
					}

                    .item-grade {
                        // width: 135px;
						min-width: 135px;
						width: 10%;
                        cursor: pointer;

                        &-text {
                            height: 22px;
                            padding: 0 7px;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            width: 100%;
                            text-align: center;

                            .grade-result {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                        }

                        .item-input-editing {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            height: 40px;
                        }
                    }

					.item-salary {
						// width: 91px;
						min-width: 91px;
						width: 6%;
						cursor: pointer;

						&-text {
							height: 22px;
							padding: 0 7px;
							text-align: center;
						}
					}

					.item-analysis {
						// width: 200px;
						min-width: 200px;
						width: 20%;
						cursor: pointer;

						.analysis-text {
						    width: 100%;
						    height: 38px;
						    padding: 0 7px;
						}

						.update-analysis-len {
							height: 20px;
						}
					}

					.item-location {
						// width: 69px;
						min-width: 69px;
						width: 5%;
						cursor: pointer;

						.location-text {
							width: 100%;
						    height: 38px;
						    padding: 0 7px;
						}

						.update-location-len {
							height: 20px;
							text-align: center;
						}
					}

					.item-recruiting {
						// width: 64px;
						min-width: 64px;
						width: 5%;
						text-align: center;
						cursor: pointer;

						&-text {
							height: 20px;
						    position: absolute;
						    top: 0;
						    bottom: 0;
						    margin: auto;
						    width: 100%;

						    .recruiting-result {
								width: 100%;
								height: 100%;
								padding: 0 7px;
							    white-space: nowrap;
							    text-overflow: ellipsis;
							    overflow: hidden;
							}
						}

						.item-input-editing {
						    position: absolute;
						    top: 0;
						    bottom: 0;
						    margin: auto;
						    height: 40px;
						    z-index: 10;
						    width: 100px;

						    .el-input__inner {
								width: 100px;
								height: 40px;
								padding-right: 25px;
								position: relative;
						    }
						}

						.count-tip {
							position: absolute;
						    width: 14px;
						    top: 0;
						    bottom: 0;
						    margin: auto;
						    right: 6px;
						    height: 18px;
						    line-height: 18px;
						    color: #666;
						}
					}

					.job-drop-item {
						display: inline-block;
					    width: 70px;
					    height: 79px;
					    line-height: 79px;
					    cursor: pointer;
					    border-right: 1px solid #ddd;
					    text-align: center;

						.drop-item-select {

					    	.el-dropdown {

								.select-result {
									text-overflow: ellipsis;
									overflow: hidden;
									white-space: nowrap;

									&:hover {
										color: #38bc9c;
									}
								}
					    	}
					    }
					}

					.drop-item-year {
						// width: 75px;
						min-width: 75px;
						width: 5%;

						.drop-item-select {

					    	.el-dropdown {

								.select-result {
									width: 70px;
								}
					    	}
					    }
					}

					.drop-item-degree {
						// width: 90px;
						min-width: 90px;
						width: 6%;

						.drop-item-select {

					    	.el-dropdown {

								.select-result {
									width: 90px;
								}
					    	}
					    }
					}

					.drop-item-emergency {
						// width: 66px;
						min-width: 66px;
						width: 5%;

						.drop-item-select {

					    	.el-dropdown {

								.select-result {
									width: 66px;
								}
					    	}
					    }
					}

					.drop-item-difficult {
						// width: 45px;
						min-width: 45px;
						width: 5%;

						border-right: none;

						.drop-item-select {

					    	.el-dropdown {

								.select-result {
									width: 45px;
								}
					    	}
					    }
					}

					.item-commission {
						width: 73px;
						cursor: pointer;

						&-text {
							height: 22px;
							padding: 0 7px;
						}
					}

					.drop-item-guarantee {
						width: 62px;
						border-right: 1px solid transparent;

						.drop-item-select {

					    	.el-dropdown {

								.select-result {
									width: 62px;
								}
					    	}
					    }
					}
				}
			}
		}

		.job-publish {
			// margin-top: 20px;
			margin: 20px 0 20px;
			line-height: 42px;

			&-item {
				display: inline-block;
				height: 42px;
				line-height: 42px;
			}

			&-checkall {
				margin-left: 12px;
				.el-checkbox {
					padding: 0;
					margin-bottom: 0;
				}
				.el-checkbox__input {
					.el-checkbox__inner {
						background: transparent;

						&:hover {
							border-color: #38bc9c;
						}
					}
				}

				.is-checked {
					.el-checkbox__inner{
						background-color: #38bc9c;
						border-color: #38bc9c;
					}
				}

				.is-focus {
					.el-checkbox__inner{
						border-color: #38bc9c;
					}
				}
			}

			&-btn {
				width: 140px;
			    height: 42px;
			    line-height: 42px;
			    background: #38bc9c;
			    color: #fff;
			    text-align: center;
			    border-radius: 4px;
			    cursor: pointer;
			    float: right;
				position: relative;
				z-index: 10;
			}

            &-count {
                margin-left: 20px;
                color: #666;
            }
			
			&-operate {
				display: inline-flex;
				margin-left: 12px;
				align-items: center;
				.job-operate {
					&-btn {
						margin: 0;
						padding: 0 8px;
						min-width: 84px;
						height: 28px;
						border: none;
						border-radius: 4px;
						color: #fff;
						line-height: 28px;
						text-align: center;
						background-color: #38bc9d;
						& + .job-operate-btn {
							margin-left: 8px;
						}
						&:hover {
							background-color: #48CAAC;
						}
						&:active, &:focus {
							background-color: #36B495;
						}
					}
					&-wantednum {
						background-color: #4A90E2;
						&:hover {
							background-color: #5199EE
						}
						&:active, &:focus {
							background-color: #347BCE;
						}
					}
					&-emergency {
						background-color: #EE9F1B;
						&:hover {
							background-color: #FFB539
						}
						&:active, &:focus {
							background-color: #E49512;
						}
					}
				}
			}
		}
	}
	.text-ellipsis {
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.position-absolut {
		position: absolute;
	    left: 0;
	    right: 0;
	    top: 0;
	    bottom: 0;
	    margin: auto;
	}

	.pre-text {
		font-family: MicrosoftYaHei;
		font-size: 14px;
		color: #666;
		padding-left: 15px;
	    white-space: pre-wrap;
	    word-wrap: break-word;
	}
	.job-edit-multiple-dialog.el-dialog {
		width: 480px !important;
        border-radius: 6px;
        margin-top: 0 !important;
        position: absolute;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
		&.more-width {
			width: 600px
		}
        .el-dialog__title{
            line-height: 18px;
            font-size: 18px;
            color: #444;
        }
        .el-dialog__headerbtn{
            line-height: 1;
        }
		.el-dialog__body{
			padding: 15px 20px 20px;
		}
		.mutiple-edit {
			&-title {
				margin-bottom: 16px;
				color: #444444;
				font-size: 14px;
				line-height: 20px;
				.text-primary {
					color: #38bc9d;
				}
				.text-warning {
					color: #F98F33;
				}
			}
			&-item {
				&.mutiple-edit-salary {
					color: #ddd;
				}
				.el-radio-group {
					.el-radio-button {
						.el-radio-button__inner {
							&:hover, &:active, &:focus {
								color: #38bc9d;
							}
						}
						&.is-active {
							.el-radio-button__inner {
								background-color: #38bc9d;
								border-color: #38bc9d;
								box-shadow: -1px 0 0 0 #38bc9d;
								&:hover, &:active, &:focus {
									color: #fff;
									background-color: #48CAAC;
									border-color: #48CAAC;
									box-shadow: -1px 0 0 0 #48CAAC;
								}
							}
						}
						&.red-fill {
							&.is-active {
								.el-radio-button__inner {
									background-color: #ff6564;
									border-color: #ff6564;
									box-shadow: -1px 0 0 0 #ff6564;
								}
							}
						}
					}
				}
				.el-input {
					width: 100px;
					line-height: 36px;
					.el-input__inner {
						border: 1px solid #ddd;
						width: 100%;
						&:hover {
							border: 1px solid #ddd;
						}
						&:focus {
							border: 1px solid #38bc9d;
						}
					}
				}
			}
			&-error {
				line-height: 20px;
				font-size: 12px;
				color: #FC7859;
			}
		}
		.el-dialog__footer {
			padding: 0 20px 20px;
			.el-button {
				min-width: 0px;
				width: 84px;
				height: 32px;
				line-height: 32px;
				padding: 0;
				border-radius: 2px;
				&.el-button--default {
					&:hover, &:active, &:focus {
						color: #38bc9d;
					}
				}
			}
			.el-button + .el-button {
				margin-left: 10px;
			}
		}
	}
</style>
