<template>
	<div class="location-multiple">
		<div class="location-header">
			<span>工作地区</span>最多选择5个城市
			<span class="location-save" @click="handleSave">保存</span>
		</div>
		<div class="selected-item">
			<div class="selected-item-text" v-for="(item, index) in tempCitys" :key="item" @click="handleDeleteCity(index)">{{getCityInfo(item).fullLabel || getCityInfo(item).label}}</div>
		</div>
		<div class="province-change">
			<div v-if="!foreignView" class="province-item province-all" @click="handleReset">全部</div>
			<span v-if="!foreignView && provinceSelected">|</span>
			<div v-if="!foreignView && provinceSelected" class="province-item province-selected">{{currentProvince.label}}</div>
            <div class="location-apply-all">
                <el-checkbox v-model="applyAll">应用到全部职位</el-checkbox>
            </div>
		</div>
		<div class="city-change">
			<div v-if="cityView" class="city-name">城市</div>
			<div v-if="!foreignView && !cityView" class="city-name">热门城市</div>
			<div v-if="!foreignView" class="location-item select-province">
				<div class="province-item" v-bind:class="{'city-active': handleBindClass(item.value), 'hot-city-item': item.fullLabe}" v-for="(item, index) in currentCities" :key="item.value" @click="handleClickCity(item.value)">{{item.label}}</div>
			</div>
			<div v-if="foreignView" class="city-name">热门国家</div>
			<div v-if="foreignView" class="location-item select-province">
				<div class="province-item" v-bind:class="{'city-active': handleBindClass(item.value), 'hot-city-item': item.fullLabe}" v-for="(item, index) in hotForeigns" :key="item.value" @click="handleClickCity(item.value)">{{item.label}}</div>
			</div>
			<div v-if="!foreignView && !cityView" class="city-name">全部省份</div>
			<div v-if="!foreignView && !cityView" class="location-item select-province">
				<div @click="handleClickProvince(index)" class="province-item province-format" v-for="(item, index) in provinceAll" :key="item.value">{{item.label}}</div>
			</div>
			<div v-if="foreignView" class="city-name">全部国家</div>
			<div v-if="foreignView" class="location-item select-province">
				<div class="province-item province-format foreign-item" v-for="(item, index) in foreignAll" :key="item.value" @click="handleClickCity(item.value)">{{item.label}}</div>
			</div>
			<div class="location-item select-city">
				<div class="city-item"></div>
			</div>
		</div>
		<div v-show="!foreignView" class="foreign-btn" @click="toogleForeignMode">
			+ 海外国家
		</div>
		<div v-show="foreignView" class="foreign-btn" @click="toogleCityMode">
			+ 国内城市
		</div>
	</div>
</template>
<script>
import ProvinceJson from '#/js/config/provinceThreeData.json';
import HotForeignData from '../config/hotForeignData.json';
import _ from 'lodash';

export default {
	name: 'LocationMultiple',
	props: {
		citys: {
			type: Array,
			default: []
		},
		index: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			provinceSelected: null,
			cityView: false,
			foreignView: false,
			currentProvince: [],
			currentCities: ProvinceJson.province[0].children,
			provinceAll: [],
			tempCitys: _.cloneDeep(this.citys),
			applyAll: false,
			foreignAll: ProvinceJson.province[ProvinceJson.province.length - 1].children,
			hotForeigns: HotForeignData.hot
		}
	},
	created() {
		console.log(ProvinceJson);
		let arr = ProvinceJson.province.slice(1);
		let length = arr.length;
		this.provinceAll = arr.slice(0, arr.length - 1);
	},
	computed: {

	},
	methods: {
		getCityInfo(id) {
			let province = ProvinceJson.province;
			for(let p = 0; p < province.length; p++) {
				let child = province[p].children;

				for(let c = 0; c < child.length; c++) {
					if(id == child[c].value) {
						return child[c];
					}
				}
			}
			return null;
		},
		handleDeleteCity(index) {
			this.tempCitys.splice(index, 1);
		},
		handleBindClass(item) {
			let result = false;
			this.tempCitys && this.tempCitys.forEach((subItem, index) =>{
				if(subItem == item) {
					result = true;
				}
			});
			return result;
		},
		handleClickCity(id) {
			this.tempCitys = this.tempCitys || [];
			let hasSelected = false;
			let index = null;
			this.tempCitys.forEach((item, subIndex) =>{
				if(item == id) {
					hasSelected = true;
					index = subIndex;
				}
			})

			if(hasSelected) {
				this.tempCitys.splice(index, 1);
			}else {
				if(this.tempCitys.length < 5) {
					this.tempCitys.push(id);
				}else {
					shortTips('最多选择5个城市');
				}
			}
		},
		handleClickProvince(index) {
			this.cityView = true;
			this.currentProvince = this.provinceAll[index];
			this.provinceSelected = true;
			this.currentCities = this.currentProvince.children;
		},
		handleReset() {
			this.cityView = false;
			this.foreignView = false;
			this.provinceSelected = null;
			this.currentCities = ProvinceJson.province[0].children;
			this.currentProvince = [];
		},
		handleSave() {
            this.$emit('location-save', {value: this.tempCitys, index: this.index, applyAll: this.applyAll})
		},
		toogleForeignMode() {
			this.foreignView = true;
		},
		toogleCityMode() {
			this.foreignView = false;
		}
	}
}
</script>
<style lang="scss">
	.location-multiple {
		width: 100%;
		font-size: 14px;

		.location-header {
			color: #b9b9b9;
			font-size: 12px;
			height: 32px;
			line-height: 32px;
			margin-bottom: 10px;

			span:nth-child(1) {
				color: #444;
				font-size: 18px;
				padding-right: 10px;
			}

			span:nth-child(2) {
				border-radius: 16px;
			    display: inline-block;
			    height: 32px;
			    line-height: 32px;
			    width: 77px;
			    text-align: center;
			    position: relative;
			    left: 225px;
			    color: #999;
			    cursor: pointer;
			    text-indent: 20px;

			    &::after {
			    	content: ' ';
			    	position: absolute;
					bottom: 0;
					top: 0;
					left: 15px;
					margin: auto;
					width: 16px;
					height: 16px;
					background: url('~@src/assets/images/jobCreateMulti/icon-save-default.png') no-repeat;
			    }

				&:hover {
					color: #83b39c;
					background: #ecf9f6;

					&::after {
						background: url('~@src/assets/images/jobCreateMulti/icon-save-active.png') no-repeat;
					}
				}
			}
		}

		.selected-item {
			min-height: 32px;

			&-text {
				height: 32px;
			    line-height: 32px;
			    padding: 0 12px;
			    display: inline-block;
			    color: #38bc9c;
			    background: #ecf9f6;
			    border-radius: 16px;
			    cursor: pointer;
				margin-right: 13px;
			}
		}

		.province-change {
			margin-top: 10px;
		    border-top: 1px solid #eee;
		    padding-top: 15px;
		    color: #d8d8d8;
			position: relative;
			height: 45px;

			.province-item {
				font-size: 14px;
				min-width: 50px;
				display: inline-block;
				cursor: pointer;
				margin-right: 10px;
				position: relative;
				color: #666;

				&::after {
					content: ' ';
					position: absolute;
					top: 0;
					bottom: 0;
					right: 5px;
					width: 12px;
					height: 12px;
					margin: auto;
				}
			}

			.province-all {
				color: #444;
                margin-bottom: 10px;

				&::after {
					background: url('~@src/assets/images/jobCreateMulti/icon-down-arrow.png') no-repeat;
				}
			}

			.province-selected {
				position: relative;
				text-align: center;
				color: #38bc9c;
				margin-left: 10px;

				&::after {
					background: url('~@src/assets/images/jobCreateMulti/icon-down-arrow-active.png') no-repeat;
					right: -15px;
				}
			}

            .location-apply-all {
                position: absolute;
                top: 12px;
                right: 0;

                .el-checkbox__inner {
                    &:hover {
                        border-color: #3abc9c;
                    }
                }

                .el-checkbox__input.is-checked .el-checkbox__inner {
                    background-color: #3abc9c;
                    border-color: #3abc9c;
                }

                .el-checkbox__input.is-focus .el-checkbox__inner {
                    border-color: #3abc9c;
                }

                .el-checkbox .el-checkbox__label {
                    font-size: 12px;
                    color: #666;
                }

                .el-checkbox__input.is-checked+.el-checkbox__label {
                    color: #666;
                }
            }
		}

		.city-change {
            overflow-y: auto;
            max-height: 240px;

            .city-name {
                margin-top: 10px;
                color: #444;
                margin-bottom: 10px;
            }

            .location-item {
                padding-left: 15px;

                .province-item {
                    height: 32px;
                    line-height: 32px;
                    padding: 0 12px;
                    display: inline-block;
                    border-radius: 16px;
                    margin: 0 20px 10px 0;
                    cursor: pointer;
					color: #666;
					white-space: nowrap;

                    &:hover {
                        background-color: #ecf9f6;
                    }
                }
            }
        }

        .province-format {
            max-width: 70px;
            margin-right: 5px;
			padding: 0 10px;
			
			&.foreign-item {
				max-width: unset;
			}
        }

        .hot-city-item {
            padding: 0 5px;
            margin: 0 4px 0 0;
        }

		.city-active {
			color: #38bc9c;
			/*background: #ecf9f6;*/
		}

		.foreign-btn {
            margin-top: 20px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            font-size: 14px;
            color: #666;
            border: 1px dashed #666660;
            cursor: pointer;
        }
	}
</style>
