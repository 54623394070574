var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "location-multiple" }, [
    _c("div", { staticClass: "location-header" }, [
      _c("span", [_vm._v("工作地区")]),
      _vm._v("最多选择5个城市\n\t\t\t"),
      _c(
        "span",
        { staticClass: "location-save", on: { click: _vm.handleSave } },
        [_vm._v("保存")]
      ),
    ]),
    _c(
      "div",
      { staticClass: "selected-item" },
      _vm._l(_vm.tempCitys, function (item, index) {
        return _c(
          "div",
          {
            key: item,
            staticClass: "selected-item-text",
            on: {
              click: function ($event) {
                return _vm.handleDeleteCity(index)
              },
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.getCityInfo(item).fullLabel || _vm.getCityInfo(item).label
              )
            ),
          ]
        )
      }),
      0
    ),
    _c("div", { staticClass: "province-change" }, [
      !_vm.foreignView
        ? _c(
            "div",
            {
              staticClass: "province-item province-all",
              on: { click: _vm.handleReset },
            },
            [_vm._v("全部")]
          )
        : _vm._e(),
      !_vm.foreignView && _vm.provinceSelected
        ? _c("span", [_vm._v("|")])
        : _vm._e(),
      !_vm.foreignView && _vm.provinceSelected
        ? _c("div", { staticClass: "province-item province-selected" }, [
            _vm._v(_vm._s(_vm.currentProvince.label)),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "location-apply-all" },
        [
          _c(
            "el-checkbox",
            {
              model: {
                value: _vm.applyAll,
                callback: function ($$v) {
                  _vm.applyAll = $$v
                },
                expression: "applyAll",
              },
            },
            [_vm._v("应用到全部职位")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "city-change" }, [
      _vm.cityView
        ? _c("div", { staticClass: "city-name" }, [_vm._v("城市")])
        : _vm._e(),
      !_vm.foreignView && !_vm.cityView
        ? _c("div", { staticClass: "city-name" }, [_vm._v("热门城市")])
        : _vm._e(),
      !_vm.foreignView
        ? _c(
            "div",
            { staticClass: "location-item select-province" },
            _vm._l(_vm.currentCities, function (item, index) {
              return _c(
                "div",
                {
                  key: item.value,
                  staticClass: "province-item",
                  class: {
                    "city-active": _vm.handleBindClass(item.value),
                    "hot-city-item": item.fullLabe,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleClickCity(item.value)
                    },
                  },
                },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            0
          )
        : _vm._e(),
      _vm.foreignView
        ? _c("div", { staticClass: "city-name" }, [_vm._v("热门国家")])
        : _vm._e(),
      _vm.foreignView
        ? _c(
            "div",
            { staticClass: "location-item select-province" },
            _vm._l(_vm.hotForeigns, function (item, index) {
              return _c(
                "div",
                {
                  key: item.value,
                  staticClass: "province-item",
                  class: {
                    "city-active": _vm.handleBindClass(item.value),
                    "hot-city-item": item.fullLabe,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleClickCity(item.value)
                    },
                  },
                },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.foreignView && !_vm.cityView
        ? _c("div", { staticClass: "city-name" }, [_vm._v("全部省份")])
        : _vm._e(),
      !_vm.foreignView && !_vm.cityView
        ? _c(
            "div",
            { staticClass: "location-item select-province" },
            _vm._l(_vm.provinceAll, function (item, index) {
              return _c(
                "div",
                {
                  key: item.value,
                  staticClass: "province-item province-format",
                  on: {
                    click: function ($event) {
                      return _vm.handleClickProvince(index)
                    },
                  },
                },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            0
          )
        : _vm._e(),
      _vm.foreignView
        ? _c("div", { staticClass: "city-name" }, [_vm._v("全部国家")])
        : _vm._e(),
      _vm.foreignView
        ? _c(
            "div",
            { staticClass: "location-item select-province" },
            _vm._l(_vm.foreignAll, function (item, index) {
              return _c(
                "div",
                {
                  key: item.value,
                  staticClass: "province-item province-format foreign-item",
                  on: {
                    click: function ($event) {
                      return _vm.handleClickCity(item.value)
                    },
                  },
                },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._m(0),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.foreignView,
            expression: "!foreignView",
          },
        ],
        staticClass: "foreign-btn",
        on: { click: _vm.toogleForeignMode },
      },
      [_vm._v("\n\t\t\t+ 海外国家\n\t\t")]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.foreignView,
            expression: "foreignView",
          },
        ],
        staticClass: "foreign-btn",
        on: { click: _vm.toogleCityMode },
      },
      [_vm._v("\n\t\t\t+ 国内城市\n\t\t")]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "location-item select-city" }, [
      _c("div", { staticClass: "city-item" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }