var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "step-process" }, [
    _vm._m(0),
    _c("div", { staticClass: "step-content" }, [
      _c("div", { staticClass: "step-bar" }, [
        _c("div", {
          staticClass: "bar-icon",
          class: { "bar-icon-0": _vm.step == 0, "bar-icon-1": _vm.step > 0 },
        }),
        _c("div", {
          staticClass: "bar-icon",
          class: { "bar-check-0": _vm.step < 2, "bar-check-1": _vm.step >= 2 },
        }),
        _c("div", {
          staticClass: "bar-icon",
          class: {
            "bar-launch-0": _vm.step < 4,
            "bar-launch-1": _vm.step >= 4,
          },
        }),
      ]),
      _c("div", { staticClass: "step-bar step-bar-process" }, [
        _c("div", {
          staticClass: "bar-dot bar-dot-1",
          class: {
            "bar-dot-step0": _vm.step == 0,
            "bar-dot-step2": _vm.step >= 1,
          },
        }),
        _c("div", {
          staticClass: "bar-process",
          class: { "bar-transit": this.step > 1 },
        }),
        _c("div", {
          staticClass: "bar-dot bar-dot-2",
          class: {
            "bar-dot-step0": _vm.step < 2,
            "bar-dot-step1": _vm.step == 2,
            "bar-dot-step2": _vm.step > 2,
          },
        }),
        _c("div", {
          staticClass: "bar-process",
          class: { "bar-transit": this.step > 3 },
        }),
        _c("div", {
          staticClass: "bar-dot bar-dot-3",
          class: {
            "bar-dot-step0": _vm.step < 4,
            "bar-dot-step1": _vm.step == 14,
            "bar-dot-step2": _vm.step >= 4,
          },
        }),
      ]),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "step-header" }, [
      _c("span", [_vm._v("批量创建职位")]),
      _c("span", { staticClass: "step-tip" }, [
        _c("i", { staticClass: "el-icon-info" }),
        _vm._v("创建公司内部职位，你的同事可以接单哦"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "step-bar bar-text" }, [
      _c("div", { staticClass: "text-item text-item-1" }, [
        _vm._v("上传Excel"),
      ]),
      _c("div", { staticClass: "text-item text-item-2" }, [_vm._v("检测字段")]),
      _c("div", { staticClass: "text-item text-item-3" }, [
        _vm._v("职位信息确认及发布"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }