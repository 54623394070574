var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "job-create" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading.fullscreen",
            value: _vm.loading,
            expression: "loading",
            modifiers: { fullscreen: true },
          },
        ],
        staticClass: "job-create-multiple",
      },
      [
        _c(
          "div",
          { staticClass: "job-step" },
          [_c("StepProcess", { attrs: { step: _vm.step } })],
          1
        ),
        _vm.step <= 1
          ? _c(
              "div",
              { staticClass: "job-info" },
              [
                _c("UploadFile", {
                  on: {
                    "upload-form": _vm.handleUploadForm,
                    "update-step": _vm.handleUpdateStep,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.step == 2 || _vm.step == 3
          ? _c(
              "div",
              { staticClass: "job-keys" },
              [
                _c("KeysMatch", {
                  attrs: {
                    jobItems: _vm.jobItems,
                    systemItems: _vm.systemItems,
                  },
                  on: {
                    "update-step": _vm.handleUpdateStep,
                    "set-match": _vm.handleSetMatch,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.step > 3
          ? _c(
              "div",
              { staticClass: "job-view" },
              [
                _c("JobEdit", {
                  attrs: {
                    jobData: _vm.jobData,
                    "batch-no": _vm.batchNo,
                    customerName: _vm.customerName,
                  },
                  on: {
                    "job-publish": _vm.handleJobPublish,
                    "multiple-edit": _vm.handleMultipleEdit,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }