import { jobCreateMulti as jobCreateMultiUrl } from '#/js/config/api.json';

export default {
    batchImport(params) {
        return _request({
            method: 'POST',
            url: jobCreateMultiUrl.batchImport,
            data: params
        })
    },
    getMappingRule() {
        return _request({
            method: 'GET',
            url: jobCreateMultiUrl.getMappingRule
        })
    },
    getImportJobs(batchNo, params) {
        return _request({
            method: 'POST',
            url: jobCreateMultiUrl.getImportJobs.replace(/\%p/ig, batchNo),
            data: params
        })
    },
    caculateGradeAndSalary(params) {
        return _request({
            method: 'GET',
            url: jobCreateMultiUrl.caculateGradeAndSalary,
            data: params
        })
    },
    publishJobs(batchNo, params) {
        return _request({
            method: 'POST',
            url: jobCreateMultiUrl.save.replace(/\%p/ig, batchNo),
            data: params
        })
    }
}