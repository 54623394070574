<template>
    <div class="job-upload">
        <div class="upload-tips">
            <span>TIPS:</span>
            (仅支持同一公司的职位批量创建，请选择公司后，将此公司的职位Excel上传)
        </div>
        <div class="info-content">
            <el-form :rules="rules" :model="jobForm" ref="jobForm">
                <el-form-item prop="customerId" label="所属公司" label-width="150px">
                    <!-- <ats-tree
                        :treeData="companyData"
                        :treeProps="treeProps"
                        :autoHideTree="true"
                        v-model="jobForm.customerId"
                        placeholder="请选择公司"
                        @setRecruitDepts="setRecruitCompany">
                    </ats-tree> -->
                    <customer-select
                        ref="customerSelect"
                        class="col-11"
                        :customer-name="customerName"
                        @customerSelected="handleCustomerNodeClick"
                    ></customer-select>
                    <span
                        class="add-customer-btn"
                        @click="handleCustomerCreate">
                        <i class="el-icon-circle-plus-outline"></i>
                        <em>{{isCustomer ? '添加客户': '申请创建客户'}}</em>
                    </span>
                </el-form-item>
                <el-form-item
                    label="所属BG/BU/部门"
                    prop="customerDepartmentId"
                    label-width="150px">
                    <span class="col-11">
                        <customer-department-select
                            ref="departmentSelect"
                            :isSearchOnlyLeaf="true"
                            :isOnlyLeaf="true"
                            :customerId="jobForm.customerId"
                            :isDisabled="!jobForm.customerId"
                            :departmentId="jobForm.customerDepartmentId"
                            @update-department="updateDepartmentId"
                        ></customer-department-select>
                    </span>
                    <span
                        class="add-department-btn"
                        v-if="jobForm.customerId"
                        @click="dispatch('App', 'createDepartment', {
                            customerId: jobForm.customerId,
                            customerName: customerName
                        })">
                        <i class="el-icon-circle-plus-outline"></i>
                        <em>添加BG/BU/部门</em>
                    </span>
                </el-form-item>
                <el-form-item
                    label="所属项目"
                    prop="projectId"
                    :rules="userInfo.isCFUser ? rules.projectId: {}"
                    label-width="150px">
                    <project-select
                        class="col-11"
                        ref="projectSelect"
                        placeholder="请输入所属项目"
                        :isDisabled="!(jobForm.customerId && jobForm.customerDepartmentId)"
                        @projectSelected="changeProject"
                    ></project-select>
                    <span
                        class="add-customer-btn"
                        @click="handleProjectCreate">
                        <i class="el-icon-circle-plus-outline"></i>
                        <em>添加项目</em>
                    </span>
                </el-form-item>
                <el-form-item
                    v-if="jobForm.projectId"
                    label="项目目标"
                    label-width="150px">
                    <ul class="goal-list col-11" v-if="projectGoalList && projectGoalList.length > 0">
                        <li v-for="goal in (isShowMoreGoal ? projectGoalList : projectGoalList.slice(0, 1))" :key="goal.id">
                            <span>{{goal.monthly}}:</span>
                            <span>月推荐:{{goal.goalOfRecommandation}}</span>
                            <span>月面试:{{goal.goalOfInterview}}</span>
                            <span>月offer:{{goal.goalOfOffer}}</span>
                        </li>
                        <span
                            v-if="projectGoalList.length > 1"
                            class="text-operate-btn more-btn"
                            @click="isShowMoreGoal = !isShowMoreGoal">
                            {{isShowMoreGoal ? '收起' : '展开更多'}}
                            <i
                                class="more-btn-icon icon-font"
                                :class="isShowMoreGoal ? 'icon-toggle-up-double' : 'icon-toggle-down-double'"
                            ></i>
                        </span>
                    </ul>
                    <ul class="goal-list col-11" v-else>
                        <li>请添加项目目标</li>
                    </ul>
                    <span
                        class="add-customer-btn"
                        @click="handleAddProjectGoal">
                        <i class="el-icon-circle-plus-outline"></i>
                        <em>添加项目目标</em>
                    </span>
                </el-form-item>
                <el-form-item label="补充信息" label-width="150px">
                    <el-input
                        type="textarea"
                        :rows="3"
                        :maxlength="800"
                        resize="none"
                        placeholder="如薪资体系、福利、面试流程、推荐注意、公司简介等"
                        v-model="jobForm.description">
                    </el-input>
                </el-form-item>
                <div class="upload-field">
                    <el-form-item label="上传文件" label-width="150px">
                        <el-upload
                            class="job-upload-file"
                            drag
                            :limit="1"
                            :auto-upload="false"
                            :on-change="handleGetFile"
                            :on-exceed="handleExceed"
                            action="//www.libide.com">
                            <div v-bind:class="{'file-name': fileName !== '将职位Excel拖拽至此区'}" class="excel-upload-icon">
                                {{fileName | formatFileName}}
                                <span @click="handleDeleteFile($event)" v-if="fileName !== '将职位Excel拖拽至此区'" class="file-delete"></span>
                            </div>
                            <div class="excel-upload-status">{{statusText}}</div>
                            <div class="upload-btn">
                                <el-button type="success" size="medium" class="btn-item" v-if="!enableNextBtn">选择Excel</el-button>
                            </div>
                        </el-upload>
                    </el-form-item>
                    <div class="excel-upload-modal" v-if="enableNextBtn"></div>
                    <el-button type="success" size="medium" class="btn-item next-step" v-if="enableNextBtn" @click="handleUploadForm">下一步</el-button>
                </div>
            </el-form>
            <div class="upload-footer">
                <span>TIPS:</span>
                为确保职位信息被正确识别，请尽可能使Excel的表头符合如下文字规范：职位名称（必填）、职位描述（必填）、工作地区、详细地址、招聘人数、职位分析、紧急程度、月薪、工作年限、最低学历；或者
                <a href="//hstatic.hirede.com/lbd/example-file/猎必得职位导入样例.xlsx">猎必得职位导入样例</a>填入信息再上传。
                <span class="warn-text">注意： 表头标题必须在表格第一行</span>
            </div>
        </div>
        <customer-create-dialog ref="customerCreateDialog" @refresh-customer-data="getCustomerData"></customer-create-dialog>
        <project-create-dialog ref="projectCreateDialog" @refresh-project-list="refreshPorjectList"></project-create-dialog>

        <!-- 添加项目交互目标弹出窗口 -->
        <add-program-goal
            v-if="isAddGoal"
            :goalParams="goalParams"
            :baseData="projectInfo"
            @add-goal-finish="addGoalFinish"
            :programGoalTitle="false"
        />
    </div>
</template>

<script>
import { jobMultiCreateRules } from '#/js/util/rules.js';
import CustomerSelect from '#/component/common/customer-select.vue'
// import { request } from '@/utils/api.js';
// import AtsTree from '../widgets/AtsTree.vue';
import CustomerDepartmentSelect from '#/component/common/customer-department-select.vue'
import CustomerCreateDialog from '#/views/customer-list/component/customerCreateDialog.vue'
import ProjectSelect from '#/component/common/project-select.vue';
import ProjectCreateDialog from '#/views/project/component/create-project-dialog.vue';
import emitter from '#/js/mixins/emitter.js';
import jobCreateMultiService from '#/js/service/jobCreateMultiService.js';

import projectDetailService from '#/js/service/projectDetailService.js';
import AddProgramGoal from '#/views/program-detail/component/add-program-goal.vue';
import moment from "moment";

const FILE_LIMIT = 1024 * 1024 * 2;

export default {
    name: 'UploadFile',
    data() {
        return {
            rules: jobMultiCreateRules({}),
            fileName: '将职位Excel拖拽至此区',
            statusText: 'or',
            firstLoad: true,
            jobForm: {
                customerId: '',
                customerDepartmentId: '',
                projectId: '',
                description: '',
                file: null
            },
            treeProps: {
                label: 'customerName',
                children: 'subCustomers',
                level: 'level'
            },
            customerName: '',
            isAddGoal: false,
            isShowMoreGoal: false,
            goalParams: {},
            projectInfo: null,
            projectGoalList: []
        }
    },
    components: {
        // AtsTree
        CustomerSelect,
        CustomerCreateDialog,
        CustomerDepartmentSelect,
        ProjectSelect,
        ProjectCreateDialog,
        AddProgramGoal
    },
    mixins: [emitter],
    computed: {
        enableNextBtn() {
            return this.jobForm.customerId && this.jobForm.customerDepartmentId && this.jobForm.file;
        },
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isCustomer() {
            return this.userInfo.privilegeCodeList?.includes('CustomerManage');
        },
    },
    filters: {
        formatFileName(val) {
            if(val && val.length > 30) {
                return val.slice(0, 30) + '...';
            }
            return val;
        }
    },
    mounted() {
    },
    methods: {
        getCustomerData() {
            this.$refs.customerSelect.getCustomerList();
        },
        handleCustomerCreate() {
            this.$refs.customerCreateDialog.show();
        },
        handleProjectCreate() {
            this.$refs.projectCreateDialog.show();
        },
        updateDepartmentId(department) {
            this.jobForm.customerDepartmentId = department.id;

            this.$refs.projectSelect.reset();
            this.jobForm.projectId = '';
            if(this.jobForm.customerId && this.jobForm.customerDepartmentId) {
                this.$refs.projectSelect.getProjectList({
                    customerId: this.jobForm.customerId,
                    customerDepartmentId: this.jobForm.customerDepartmentId
                });
            }
        },
        handleCustomerNodeClick(val) {
            if(val.customerId != this.jobForm.customerId) {
                if(this.jobForm.customerId != '') {
                    this.jobForm.customerDepartmentId = '';
                    this.$refs['jobForm'].validateField('customerDepartmentId');
                }

                this.$refs.departmentSelect.reset();
                this.jobForm.customerId = val.customerId;
                this.customerName = val.customerName
                this.$refs['jobForm'].validateField('customerId');
                this.$refs.projectSelect.reset();
                this.jobForm.projectId = '';
            }
        },
        changeProject(project) {
            this.jobForm.projectId = project.projectId;
            this.$refs.jobForm.validateField('projectId', (valid) => {});

            this.projectInfo = null;
            if (project.projectId) {
                this.handleGetProjectInfo();
                this.handleGetGoalList();
            }
        },
        refreshPorjectList() {
            if(this.jobForm.customerId && this.jobForm.customerDepartmentId) {
                this.$refs.projectSelect.getProjectList({
                    customerId: this.jobForm.customerId,
                    customerDepartmentId: this.jobForm.customerDepartmentId
                });
            }
        },
        setRecruitCompany(val) {
            if(!this.firstLoad) {
                this.jobForm.customerId = val.customerId;
                this.customerName = val.customerName
                this.$refs['jobForm'].validateField('customerId');
            }
            this.firstLoad = false;
        },
        handleUplodInfo() {
            debugger;
        },
        handleGetFile(file) {
            if(file.name.split('.')[1] !== 'xlsx' && file.name.split('.')[1] !== 'xls') {
                shortTips('请上传 Excel 文件');
                return false;
            }
            if(file.size > FILE_LIMIT) {
                shortTips('文件大小超过 2M');
                return false;
            }
            this.fileName = file.name;
            this.statusText = '上传完成';
            this.jobForm.file = file.raw;
            this.handleSetStep(1);
            this.$refs['jobForm'].validate();
        },
        handleExceed(files, fileList) {
            let file = files[0];
            file.raw = file;
            this.handleGetFile(file);
        },
        handleUploadForm() {
            this.$refs['jobForm'].validate(valid =>{
                if(valid && this.jobForm.file) {
                    this.$emit('upload-form', {formValue: this.jobForm, customerName: this.customerName});
                }else {
                    shortTips('表单数据不完整')
                }
            })
        },
        handleSetStep(step) {
            this.$emit('update-step', step)
        },
        handleDeleteFile(e) {
            e.stopPropagation();
            this.fileName = '将职位Excel拖拽至此区';
            this.statusText = 'or';
            this.jobForm.file = null;
        },
        handleGetProjectInfo() {
            const param = {
                id: this.jobForm.projectId,
            };
            projectDetailService
                .getProjectDetailInfo(param)
                .then((res) => {
                    this.projectInfo = res;

                    this.goalParams = { 
                        type: "add",
                        businessType: "project",
                        porjectId: this.jobForm.projectId,
                        projectName: this.projectInfo.name,
                    };
                });
        },
        handleGetGoalList() {
            const params = {
                projectId: this.jobForm.projectId,
            };
            projectDetailService.getProjectGoal(params).then((res) => {
                let list = res || [];
                list = list.filter(item => {
                    return moment(item.monthly) >= moment().startOf('month');
                });

                this.projectGoalList = list;
            });
        },
        handleAddProjectGoal() {
            if (this.projectInfo) {
                this.isAddGoal = true;
            } else {
                const param = {
                    id: this.jobForm.projectId,
                };
                projectDetailService
                    .getProjectDetailInfo(param)
                    .then((res) => {
                        this.projectInfo = res;

                        this.goalParams = { 
                            type: "add",
                            businessType: "project",
                            porjectId: this.jobForm.projectId,
                            projectName: this.projectInfo.name,
                        };
                        this.isAddGoal = true;
                    }).catch(err => {
                        shortTips("获取项目信息失败，请稍后重试！");
                    });
            }
        },
        addGoalFinish() {
            this.handleGetGoalList();
            this.isAddGoal = false;
        },
    }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1400px) {
    .info-content /deep/ { 
        width: 1000px !important;
        .el-textarea__inner, .el-upload-dragger {
            width: 800px !important;
        }
        .upload-field .next-step {
            left: 485px !important;
        }
    }
}
.job-upload {

    .upload-tips {
        padding-left: 50px;
        padding-bottom: 18px;
        color: #989a9c;
        font-size: 12px;
        // margin-bottom: 6px;
        border-bottom: 1px solid #EEEEEE;

        span {
            padding-right: 5px;
            font-size: 14px;
            color: #38BC9C;
        }
    }

    .info-content /deep/ {
        // width: 100%;
        // background-color: #f8f8f8;
        // height: 582px;
        // padding: 45px 60px 35px;

        width: 1200px;
        padding: 50px 50px 84px;

        .el-form-item__label {
            text-align: left;
            padding-left: 10px;
        }
        .el-form-item.is-required .el-form-item__label {
            padding-left: 0;
        }

        .add-customer-btn, .add-department-btn {
            display: inline-block;
            height: 40px;
            margin-left: 30px;
            font-size: 15px;
            color: $primary;
            cursor: pointer;

            > i {
                font-size: 22px;
                margin-right: 6px;
                line-height: 40px;
            }

            > em {
                vertical-align: top;
            }
        }

        .customer-input, .el-input__inner {
            width: 542px;
        }

        .ats-tree-scrollbar {
            .el-scrollbar__wrap {
                .ats-tree-node__content:hover {
                    background: #eaf8f4;
                }
            }
        }

        .is-error {
            .el-input__inner {
                border-color: #FF6600;
            }
        }

        .el-form-item__error {
            color: #FF6600;
        }

        .ats-tree-scrollbar {
            width: 542px;
        }

        .el-input__inner:focus {
            border-color: #38bc9c;
        }

        .el-textarea__inner:focus {
            border-color: #38bc9c;
        }

        .el-upload-dragger:hover {
            border-color: #38bc9c;
        }

        .el-upload-dragger.is-dragover {
            border-color: #38bc9c;
        }

        .ats-tree-node__content {
            text-indent: 0 !important;
        }

        .btn-item {
            width: 140px;
            height: 42px;
            background: #38BC9C;
        }

        .upload-field {
            position: relative;

            .job-upload-file {
                position: relative;

                .el-upload-dragger {
                    width: 930px;
                    height: 195px;
                }

                .el-upload-list {
                    display: none;
                }

                .excel-upload-icon {
                    margin-top: 42px;
                    color: #999;
                    display: inline-block;
                    position: relative;

                    &::before {
                        content: ' ';
                        width: 23px;
                        height: 23px;
                        background: url('~@src/assets/images/jobCreateMulti/icon-excel-active-small.png') no-repeat;
                        left: -30px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        position: absolute;
                    }

                    .file-delete {
                        display: inline-block;
                        width: 17px;
                        height: 12px;
                        margin-left: 7px;
                        vertical-align: middle;
                        background: url('~@src/assets/images/jobCreateMulti/icon-close-btn.png') no-repeat;
                        position: absolute;
                        z-index: 10;
                        right: -30px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }

                .file-name {
                    color: #38bc9c;
                }

                .excel-upload-status {
                    color: #999;
                    font-size: 12px;
                }

                .upload-btn {
                }
            }

            .excel-upload-modal {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: transparent;
            }

            .next-step {
                position: absolute;
                left: 545px;
                margin: auto;
                top: 114px;
                z-index: 20;
            }
        }

        .el-textarea__inner {
            width: 930px;
        }

        .upload-footer {
            color: #999;
            line-height: 20px;

            span {
                color: #38bc9c;
            }

            a {
                color: #38bc9c;
                text-decoration: underline;
                padding: 0 5px;
            }

            .warn-text {
                color: #FF6600;
            }
        }
    }

    .info-content .goal-list li {
        height: 40px;
        line-height: 40px;
        border: 1px solid #E4E7ED;
        border-radius: 4px;
        color: #9d9d9d;
        background-color: #F5F7FA;
        margin-bottom: 22px;
        padding: 0 15px;
        white-space: nowrap;

        span {
            display: inline-block;
            min-width: 88px;
            white-space: nowrap;
        }
        
        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>

<style lang="scss">
.customer-select.el-select-dropdown {
    min-width: 542px !important;
    max-width: 542px;
}
.department-select.el-select-dropdown {
    min-width: 542px !important;
    max-width: 542px;
}
</style>
