var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "job-upload" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "info-content" },
        [
          _c(
            "el-form",
            { ref: "jobForm", attrs: { rules: _vm.rules, model: _vm.jobForm } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "customerId",
                    label: "所属公司",
                    "label-width": "150px",
                  },
                },
                [
                  _c("customer-select", {
                    ref: "customerSelect",
                    staticClass: "col-11",
                    attrs: { "customer-name": _vm.customerName },
                    on: { customerSelected: _vm.handleCustomerNodeClick },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "add-customer-btn",
                      on: { click: _vm.handleCustomerCreate },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                      _c("em", [
                        _vm._v(
                          _vm._s(_vm.isCustomer ? "添加客户" : "申请创建客户")
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所属BG/BU/部门",
                    prop: "customerDepartmentId",
                    "label-width": "150px",
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "col-11" },
                    [
                      _c("customer-department-select", {
                        ref: "departmentSelect",
                        attrs: {
                          isSearchOnlyLeaf: true,
                          isOnlyLeaf: true,
                          customerId: _vm.jobForm.customerId,
                          isDisabled: !_vm.jobForm.customerId,
                          departmentId: _vm.jobForm.customerDepartmentId,
                        },
                        on: { "update-department": _vm.updateDepartmentId },
                      }),
                    ],
                    1
                  ),
                  _vm.jobForm.customerId
                    ? _c(
                        "span",
                        {
                          staticClass: "add-department-btn",
                          on: {
                            click: function ($event) {
                              return _vm.dispatch("App", "createDepartment", {
                                customerId: _vm.jobForm.customerId,
                                customerName: _vm.customerName,
                              })
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-circle-plus-outline",
                          }),
                          _c("em", [_vm._v("添加BG/BU/部门")]),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所属项目",
                    prop: "projectId",
                    rules: _vm.userInfo.isCFUser ? _vm.rules.projectId : {},
                    "label-width": "150px",
                  },
                },
                [
                  _c("project-select", {
                    ref: "projectSelect",
                    staticClass: "col-11",
                    attrs: {
                      placeholder: "请输入所属项目",
                      isDisabled: !(
                        _vm.jobForm.customerId &&
                        _vm.jobForm.customerDepartmentId
                      ),
                    },
                    on: { projectSelected: _vm.changeProject },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "add-customer-btn",
                      on: { click: _vm.handleProjectCreate },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                      _c("em", [_vm._v("添加项目")]),
                    ]
                  ),
                ],
                1
              ),
              _vm.jobForm.projectId
                ? _c(
                    "el-form-item",
                    { attrs: { label: "项目目标", "label-width": "150px" } },
                    [
                      _vm.projectGoalList && _vm.projectGoalList.length > 0
                        ? _c(
                            "ul",
                            { staticClass: "goal-list col-11" },
                            [
                              _vm._l(
                                _vm.isShowMoreGoal
                                  ? _vm.projectGoalList
                                  : _vm.projectGoalList.slice(0, 1),
                                function (goal) {
                                  return _c("li", { key: goal.id }, [
                                    _c("span", [
                                      _vm._v(_vm._s(goal.monthly) + ":"),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "月推荐:" +
                                          _vm._s(goal.goalOfRecommandation)
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "月面试:" + _vm._s(goal.goalOfInterview)
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "月offer:" + _vm._s(goal.goalOfOffer)
                                      ),
                                    ]),
                                  ])
                                }
                              ),
                              _vm.projectGoalList.length > 1
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "text-operate-btn more-btn",
                                      on: {
                                        click: function ($event) {
                                          _vm.isShowMoreGoal =
                                            !_vm.isShowMoreGoal
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.isShowMoreGoal
                                              ? "收起"
                                              : "展开更多"
                                          ) +
                                          "\n                        "
                                      ),
                                      _c("i", {
                                        staticClass: "more-btn-icon icon-font",
                                        class: _vm.isShowMoreGoal
                                          ? "icon-toggle-up-double"
                                          : "icon-toggle-down-double",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          )
                        : _c("ul", { staticClass: "goal-list col-11" }, [
                            _c("li", [_vm._v("请添加项目目标")]),
                          ]),
                      _c(
                        "span",
                        {
                          staticClass: "add-customer-btn",
                          on: { click: _vm.handleAddProjectGoal },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-circle-plus-outline",
                          }),
                          _c("em", [_vm._v("添加项目目标")]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "补充信息", "label-width": "150px" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      maxlength: 800,
                      resize: "none",
                      placeholder:
                        "如薪资体系、福利、面试流程、推荐注意、公司简介等",
                    },
                    model: {
                      value: _vm.jobForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.jobForm, "description", $$v)
                      },
                      expression: "jobForm.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "upload-field" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传文件", "label-width": "150px" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "job-upload-file",
                          attrs: {
                            drag: "",
                            limit: 1,
                            "auto-upload": false,
                            "on-change": _vm.handleGetFile,
                            "on-exceed": _vm.handleExceed,
                            action: "//www.libide.com",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "excel-upload-icon",
                              class: {
                                "file-name":
                                  _vm.fileName !== "将职位Excel拖拽至此区",
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm._f("formatFileName")(_vm.fileName)
                                  ) +
                                  "\n                            "
                              ),
                              _vm.fileName !== "将职位Excel拖拽至此区"
                                ? _c("span", {
                                    staticClass: "file-delete",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDeleteFile($event)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _c("div", { staticClass: "excel-upload-status" }, [
                            _vm._v(_vm._s(_vm.statusText)),
                          ]),
                          _c(
                            "div",
                            { staticClass: "upload-btn" },
                            [
                              !_vm.enableNextBtn
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "btn-item",
                                      attrs: {
                                        type: "success",
                                        size: "medium",
                                      },
                                    },
                                    [_vm._v("选择Excel")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.enableNextBtn
                    ? _c("div", { staticClass: "excel-upload-modal" })
                    : _vm._e(),
                  _vm.enableNextBtn
                    ? _c(
                        "el-button",
                        {
                          staticClass: "btn-item next-step",
                          attrs: { type: "success", size: "medium" },
                          on: { click: _vm.handleUploadForm },
                        },
                        [_vm._v("下一步")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._m(1),
        ],
        1
      ),
      _c("customer-create-dialog", {
        ref: "customerCreateDialog",
        on: { "refresh-customer-data": _vm.getCustomerData },
      }),
      _c("project-create-dialog", {
        ref: "projectCreateDialog",
        on: { "refresh-project-list": _vm.refreshPorjectList },
      }),
      _vm.isAddGoal
        ? _c("add-program-goal", {
            attrs: {
              goalParams: _vm.goalParams,
              baseData: _vm.projectInfo,
              programGoalTitle: false,
            },
            on: { "add-goal-finish": _vm.addGoalFinish },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "upload-tips" }, [
      _c("span", [_vm._v("TIPS:")]),
      _vm._v(
        "\n        (仅支持同一公司的职位批量创建，请选择公司后，将此公司的职位Excel上传)\n    "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "upload-footer" }, [
      _c("span", [_vm._v("TIPS:")]),
      _vm._v(
        "\n            为确保职位信息被正确识别，请尽可能使Excel的表头符合如下文字规范：职位名称（必填）、职位描述（必填）、工作地区、详细地址、招聘人数、职位分析、紧急程度、月薪、工作年限、最低学历；或者\n            "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "//hstatic.hirede.com/lbd/example-file/猎必得职位导入样例.xlsx",
          },
        },
        [_vm._v("猎必得职位导入样例")]
      ),
      _vm._v("填入信息再上传。\n            "),
      _c("span", { staticClass: "warn-text" }, [
        _vm._v("注意： 表头标题必须在表格第一行"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }