<template>
    <div>
		<div class="match-tips"><span>Tips: </span>“职位描述”和“职位分析”这两个字段可重复选择。</div>
		<div class="keys-match">
			<div class="match-header">
				<div class="match-header-item header-text">请检测是否匹配正确：</div>
				<div class="match-header-item header-excel">Excel字段</div>
				<div class="match-header-item header-space"></div>
				<div class="match-header-item header-system">系统字段</div>
			</div>
			<div class="match-content">
				<div class="match-content-item" v-for="(item, index) in jobItems" :key="index">
					<div class="item-common item-excel" v-bind:class="mapClass(index)">{{item.importItemName}}</div>
					<div class="item-common item-space"></div>
					<div class="item-common item-system">
						<el-popover
							placement="right-start"
							width="200"
							v-model="item.modelShow"
							popper-class="match-popover"
							trigger="click">
							<div slot="reference" class="item-system-key" @click="handleShowModal($event, index)">
								<span class="matching-text">{{item.itemName | mapText}}</span>
								<span class="matching-error">{{item.errMessage}}</span>
							</div>
							<div class="item-system-select">
								<div class="select-item" v-bind:class="{'seletct-item-requiry': item.required}" v-for="(item, subIndex) in systemItems" :key="subIndex" @click="handleMatch(item, index)">{{item.name}}</div>
							</div>
						</el-popover>
					</div>
				</div>
			</div>
			<div class="match-footer">
				<el-button v-if="enableNext" type="success" class="next-btn enable-next" @click="handleSetMatch">下一步 ></el-button>
				<el-button v-if="!enableNext" type="info" class="next-btn disable-next">下一步 ></el-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'KeysMatch',
	props: {
		jobItems: {
			type: Array,
			default: []
		},
		systemItems: {
			type: Array,
			default: []
		}
	},
	data() {
		return {
			enableNext: true,
			modalShow: false,
            matchSuccess: false
		}
	},
	created() {
		this.handleInitCount();
	},
    mounted() {
	    this.matchSuccess = this.handleCheck();
    },
    watch: {
	    matchSuccess(val) {
	        if(val) {
                this.$emit('update-step', 3)
            }else {
                this.$emit('update-step', 2)
            }
        }
    },
	methods: {
		handleCheck(tips) {
			let jobName = false;
			let jobDes = false;
			this.jobItems.forEach((item, index) =>{
				if(item.itemName === '职位名称') {
					jobName = true;
				}else if(item.itemName === '职位描述') {
					jobDes = true;
				}
			});
			if(!jobName || !jobDes) {
			    if(tips) {
                    shortTips("'职位名称' 和 '职位描述' 为必选项");
                }
				return false;
			}

			let hasMatchErr = true;
			this.jobItems.forEach((item, forEach) =>{
				if(item.errMessage === '* 不可重复选择') {
					hasMatchErr = false;
				}
			});
			if(!hasMatchErr) {
			    if(tips) {
                    shortTips('匹配项存在错误，请重新匹配');
                }
				return false;
			}
			return true;
		},
		getSytemItem(name) {
			let result = null;
			this.systemItems.forEach((item, index) =>{
				if(item.name === name) {
					result = item;
				}
			});
			return result;
		},
		handleSetMatch() {
			if(this.handleCheck(true)) {
				this.$emit('set-match');
			}
		},
		handleInitCount() {
			this.jobItems.forEach((item, index) =>{
				this.systemItems.forEach((subItem, subIndex) =>{
					if(item.itemName === subItem.name) {
						if(!subItem.count) {
							subItem.count = 1;
						}else {
							subItem.count ++ ;
						}
					}
				})
			});
		},
		mapClass(index) {
			let className = 'item-excel-success';
			let itemName = this.jobItems[index].itemName;
			this.systemItems.forEach((subItem, subIndex) =>{
				if(subItem.name === itemName) {
					if(subItem.count > 1 && !subItem.mutiMatch) {
						this.jobItems[index].errMessage = '* 不可重复选择';
						className = 'item-excel-wrong';
					}else {
						this.jobItems[index].errMessage = null;
						className = 'item-excel-success';
					}
				}
			});
			if(!this.jobItems[index].itemName || this.jobItems[index].itemName === '请选择') {
				this.jobItems[index].errMessage = '请选择';
				className = 'item-excel-warning';
			}

			return className;
		},
		handleMatch(item, index) {
			this.systemItems.forEach((subItem, subIndex) =>{
				if(subItem.name === this.jobItems[index].itemName) {
					subItem.count -- ;
				}
				if(subItem.name === item.name) {
					subItem.count ++ ;
				}
			})
			this.jobItems[index].itemName = item.name;
			this.jobItems = this.jobItems;
			this.jobItems[index].modelShow = false;
            this.matchSuccess = this.handleCheck();
		},
		handleShowModal(ev, index) {
			this.$set(this.jobItems[index], 'modelShow', true);

			setTimeout(() =>{
                this.$set(this.jobItems[index], 'modelShow', true);
                console.log('index value is', index, this.jobItems[index].modelShow)
            })
		}
	},
	filters: {
		mapText(val) {
			if(!val) {
				return '请选择';
			}
			return val;
		}
	}
}
</script>

<style lang="scss">
@media screen and (max-width: 1400px) {
    .keys-match {
		padding-left: 45px !important;
	}
}
	.match-tips {
            font-size: 12px;
            // text-align: right;
            color: #989A9C;
            // height: 45px;
            // line-height: 35px;

			padding: 0 0 18px 50px;
			margin-bottom: 50px;
			font-size: 12px;
			color: #989A9C;
			display: inline-block;
			border-bottom: 1px solid #ccc;
			width: 100%;

            span {
                color: #38bc9c;
            }
        }
	.keys-match {
		// width: 1190px;
		// margin: 0 auto;
		// background: #f8f8f8;
        // padding: 0 0 16px 60px;
        padding: 0 0 46px 116px;

		.match-header {

			&-item {
				font-size: 16px;
				display: inline-block;
			}

			.header-text {
				color: #444;
			}

			.header-excel {
				font-size: 16px;
				color: #999;
				margin-left: 168px;
			}

			.header-space {
			    width: 246px;
			    height: 1px;
			    vertical-align: middle;
			    border-top: 1px dashed #ddd;
			    margin-left: 15px;
			}

			.header-system {
			    color: #999;
			    width: 200px;
			    margin-left: 15px;
			}
		}

		.match-content {
			margin: 50px 0 0 250px;

		    &-item {
		    	color: #666;
		    	margin-bottom: 25px;

		    	.item-common {
		    		display: inline-block;
		    		text-align: left;
		    	}

		    	.item-excel {
					width: 150px;
					position: relative;
					text-indent: 33px;
					overflow: hidden;
				    text-overflow: ellipsis;
				    white-space: nowrap;
				    vertical-align: top;

					&::before {
						content: ' ';
						width: 20px;
						height: 20px;
						position: absolute;
						left: 0;
						top: 0;
						bottom: 0;
						margin: auto;
						background-repeat: no-repeat;
						background-position: left top;
					}
			    }

			    .item-excel-success {
			    	&::before {
						background: url('~@src/assets/images/jobCreateMulti/icon-excel-success.png');
			    	}
			    }

			    .item-excel-warning {
			    	&::before {
						background: url('~@src/assets/images/jobCreateMulti/icon-excel-warning.png');
			    	}
			    }

			    .item-excel-wrong {
			    	&::before {
						background: url('~@src/assets/images/jobCreateMulti/icon-excel-wrong.png');
			    	}
			    }

			    .item-space {
					width: 246px;
					height: 1px;
					border-top: 1px  dashed #ddd;
					vertical-align: middle;
				    margin-left: 18px;
			    }

			    .item-system {
					min-width: 80px;
				    margin-left: 15px;

				    &-key {
				    	cursor: pointer;
				    	position: relative;

						.matching-text {
							position: relative;

							&::before {
								position: absolute;
								content: ' ';
								top: 0;
								bottom: 0;
								right: -25px;
								margin: auto;
								width: 15px;
								height: 15px;
								background: url('~@src/assets/images/jobCreateMulti/icon-excel-expend.png') no-repeat;
							}
						}

						.matching-error {
							color: #FF6600;
						    padding-left: 23px;
						    position: absolute;
						    width: 135px;
						    right: -125px;
						    font-size: 12px;
						    z-index: 0;
						}
				    }
			    }
		    }
		}

		.match-footer {
			width: 1030px;
			text-align: center;
			margin-top: 42px;

			.next-btn {
				width: 140px;
				height: 42px;
				color: #fff;
				border: none;
			}

			.enable-next {
				background: #38BC9C;
			}

			.disable-next {
				background: #d8d8d8;
			}
		}
	}
</style>
